import classNames from "classnames";
import React from "react";
import { contextMenu } from "react-contexify";
import { EditText } from "react-edit-text";
import {
  CUSTOM_COLUMNS,
  CUSTOM_COLUMN_KEY,
  STATUS_FILTER_MENU_ID,
} from "../../../../common/constants";
import {
  FieldEntity,
  FieldUpdatePayload,
} from "../../../../common/types/EntityTypes";
import {
  CustomHeaderParams,
  EditTextPayload,
} from "../../../../common/types/dashboard/DashboardUITypes";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  selectShowNotes,
  toggleNotes,
} from "../../../../redux/reducers/dashboardSlice";
import {
  toggleFieldPin,
  updateFieldAttribute,
} from "../../../../redux/reducers/metadataSlice";
import {
  selectMenu,
  setMenu,
  setSettings,
} from "../../../../redux/reducers/popupSlice";
import { selectResolutionMetadata } from "../../../../redux/reducers/uiSlice";

function TableHeader(props: CustomHeaderParams) {
  const dispatch = useAppDispatch();

  const showNotes = useAppSelector(selectShowNotes);
  const dashboardData = useAppSelector(selectResolutionMetadata);

  const selectedMenu = useAppSelector(selectMenu);

  function displayMenu(e: React.MouseEvent<HTMLElement>) {
    if (
      e.currentTarget.offsetParent != null &&
      e.currentTarget.offsetParent != undefined
    ) {
      let parentDoc = e.currentTarget.offsetParent?.getBoundingClientRect();
      dispatch(setMenu(STATUS_FILTER_MENU_ID));
      contextMenu.show({
        id: selectedMenu,
        event: e,
        position: {
          x: parentDoc.left + parentDoc.width + 5,
          y: parentDoc.top + 5,
        },
      });
    }
  }

  function handleNotesVisiblity() {
    const newNotesVisibility = !showNotes;
    props.columnApi
      .getColumns()
      ?.filter(
        (col) =>
          col.isPinnedRight() &&
          (col.getColDef().cellRendererParams.customData.code ==
            CUSTOM_COLUMN_KEY.NOTES ||
            col.getColDef().cellRendererParams.customData.code ==
              CUSTOM_COLUMN_KEY.NOTES_HIDDEN)
      )
      .forEach((col) => {
        if (
          col.getColDef().cellRendererParams.customData.code ==
          CUSTOM_COLUMN_KEY.NOTES
        ) {
          if (newNotesVisibility) {
            props.columnApi.setColumnVisible(col.getColId(), true);
          } else {
            props.columnApi.setColumnVisible(col.getColId(), false);
          }
        } else if (
          col.getColDef().cellRendererParams.customData.code ==
          CUSTOM_COLUMN_KEY.NOTES_HIDDEN
        ) {
          if (newNotesVisibility) {
            props.columnApi.setColumnVisible(col.getColId(), false);
          } else {
            props.columnApi.setColumnVisible(col.getColId(), true);
          }
        }
      });
    dispatch(toggleNotes());
  }

  const handleSave = (editTextPayload: EditTextPayload) => {
    let requestPayload = {} as FieldUpdatePayload;
    requestPayload.code = props.customData.code;
    requestPayload.name = editTextPayload.value;
    requestPayload.order = props.customData.order;
    requestPayload.isPinned = props.customData.pinned;
    requestPayload.isVisible = props.customData.visible;
    dispatch(updateFieldAttribute(requestPayload));
  };

  const handleColumnPin = (toPin: boolean) => {
    dispatch(toggleFieldPin(props.customData.id));
    props.columnApi.applyColumnState({
      state: [{ colId: props.customData.name, pinned: toPin ? "left" : null }],
    });

    dispatch(
      updateFieldAttribute({
        code: props.customData.code,
        isPinned: toPin,
        isVisible: props.customData.visible,
        name: props.customData.name,
        order: props.customData.order,
      } as FieldUpdatePayload)
    );
  };

  function getHeader(header: FieldEntity) {
    if (header.code == CUSTOM_COLUMNS.PIN.key) {
      return <span></span>;
    } else if (header.code == CUSTOM_COLUMNS.NOTES_HIDDEN.key) {
      return (
        <div style={{ flexShrink: 0 }} className="flex fit-container">
          <img
            width={dashboardData.image_dimension.NOTES.widthPX}
            height={dashboardData.image_dimension.NOTES.heightPX}
            src={dashboardData.image_dimension.NOTES.show}
            onClick={handleNotesVisiblity}
          ></img>
        </div>
      );
    } else if (header.code == CUSTOM_COLUMNS.NOTES.key) {
      return (
        <div
          style={{ flexShrink: 0 }}
          className="flex fit-container notes-values-text"
        >
          <img
            className="img-no-shirnk"
            width={dashboardData.image_dimension.NOTES.widthPX}
            height={dashboardData.image_dimension.NOTES.heightPX}
            src={dashboardData.image_dimension.NOTES.hide}
            onClick={handleNotesVisiblity}
          ></img>
          {showNotes && (
            <div className="flex fit-container header-text">{header.name}</div>
          )}
        </div>
      );
    } else if (header.code == CUSTOM_COLUMNS.STATUS.key) {
      return (
        <div
          className="flex fit-container header-text header-text-align"
          onClick={(e) => displayMenu(e)}
        >
          {header.name}
        </div>
      );
    } else if (header.code == CUSTOM_COLUMNS.CHILDREN.key) {
      return (
        <img
          className="img-no-shirnk"
          width={dashboardData.image_dimension.SETTINGS.widthPX}
          height={dashboardData.image_dimension.SETTINGS.heightPX}
          src={dashboardData.image_dimension.SETTINGS.image}
          onClick={() => dispatch(setSettings())}
        ></img>
      );
    } else if (
      header.code == CUSTOM_COLUMNS.MARKET.key ||
      header.code == CUSTOM_COLUMNS.AUTHOR.key
    ) {
      return (
        <div className="flex fit-container header-text header-text-align">
          {header.name}
        </div>
      );
    }

    return (
      <div className="flex" style={{ gap: "5px", alignItems: "baseline" }}>
        <EditText
          name={header.code}
          className="header-text header-no-padding-margin"
          inputClassName="header-text input-text"
          style={{ padding: "5px" }}
          onSave={handleSave}
          defaultValue={header.name}
        ></EditText>
        <img
          className="img-no-shirnk"
          onClick={(e) => handleColumnPin(!header.pinned)}
          width={dashboardData.image_dimension.COLUMN_PIN.widthPX}
          src={
            header.pinned
              ? dashboardData.image_dimension.COLUMN_PIN.unpinnedImage
              : dashboardData.image_dimension.COLUMN_PIN.pinnedImage
          }
        ></img>
      </div>
    );
  }
  return (
    <>
      <div
        className={classNames("flex", "fit-container", "ag-cell-font-common", {
          "flex-h-align-center":
            props.customData?.code == CUSTOM_COLUMNS.STATUS.key ||
            props.customData?.code == CUSTOM_COLUMNS.CHILDREN.key,
        })}
      >
        {getHeader(props.customData)}
      </div>
    </>
  );
}
export default TableHeader;
