import { DocumentOfflineEntity } from "../common/types/DB";
import { db } from "./db";

/** Document DB Action */
export const fetchDocumentsByClient = (client: string) => {
  return db.documents.where("client").equalsIgnoreCase(client).toArray();
};

export const fetchOfflineDocumentsByClients = (client: string[]) => {
  return db.documents.where("client").anyOf(client).toArray();
};

export const upsertDocuments = (docs: DocumentOfflineEntity[]) => {
  if (docs) return db.documents.bulkPut(docs);
};

export const updatePersistanceForDocumentID = (
  documentId: string,
  isPersisted: boolean
) => {
  if (documentId) {
    return db.documents.update(documentId, {
      isPersisted: isPersisted,
    });
  }
};

export const updateDocumentReadStatus = (
  documentId: string | undefined,
  isRead: boolean,
  isPersisted: boolean
) => {
  if (documentId) {
    return db.documents.update(documentId, {
      read: isRead,
      isPersisted: isPersisted,
    });
  }
};

export const updateAuthorForDocumentID = (
  documentId: string,
  authorExternalId: string
) => {
  if (documentId && authorExternalId) {
    return db.documents.update(documentId, {
      author: authorExternalId,
      isPersisted: false,
    });
  }
};

export const updateMarketForDocumentID = (
  documentId: string,
  marketExternalId: string
) => {
  if (documentId && marketExternalId) {
    return db.documents.update(documentId, {
      market: marketExternalId,
      isPersisted: false,
    });
  }
};

export const updateLeaseStatusForDocumentID = (
  documentId: string,
  status: string
) => {
  if (documentId && status) {
    return db.documents.update(documentId, {
      status: status,
      isPersisted: false,
    });
  }
};
