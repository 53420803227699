import moment from "moment";
import "./UploadTableCell.scss";
import {
  AP_STATUS,
  BACKEND_AP_STATUS,
  DELETE_ACTION_DOC_IDS,
  UPLOAD_HEADER,
} from "../../../../common/constants";
import { CustomUploadCellParams } from "../../../../common/types/dashboard/DashboardUITypes";
import classNames from "classnames";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  updateDocumentLeaseStatus,
  cancelDocumentProcess,
} from "../../../../redux/reducers/documentsStatusSlice";

function UploadTableCell(props: CustomUploadCellParams) {
  const dispatch = useAppDispatch();
  const getCell = () => {
    const isProcessCompleted = () => props.data.percentageCompleted == 100;

    const getFontColor = () =>
      isProcessCompleted() ? "done-font-color" : "in-progress-font-color";

    const isCancellable = () =>
      props.data.percentageCompleted == 100
        ? "visible-hidden"
        : "visibilty-not-hiddent";

    const handleCancel = () => {
      let docIds = localStorage.getItem(DELETE_ACTION_DOC_IDS);
      if (docIds != null) {
        const docIdList: string[] = JSON.parse(docIds);
        docIdList.push(props.data.id);
        localStorage.setItem(DELETE_ACTION_DOC_IDS, JSON.stringify(docIdList));
      } else {
        localStorage.setItem(
          DELETE_ACTION_DOC_IDS,
          JSON.stringify([props.data.id])
        );
      }

      if (props.data.percentageCompleted > 25) {
        dispatch(updateDocumentLeaseStatus(props.data.id));
      } else {
        dispatch(cancelDocumentProcess(props.data.id));
      }
    };

    if (props.type == UPLOAD_HEADER.STATUS) {
      return (
        <div
          className={classNames(
            "document-name",
            "done-font-color",
            props.data.read ? "text-read" : "text-unread"
          )}
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          {props.data.apStatus == BACKEND_AP_STATUS.AP_COMPLETE
            ? AP_STATUS.DONE
            : props.data.apStatus}
        </div>
      );
    } else if (props.type == UPLOAD_HEADER.DOCUMENT_NAME) {
      return (
        <div
          className="flex flex-basis"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className={classNames(
              "document-name",
              "done-font-color",
              props.data.read ? "text-read" : "text-unread"
            )}
          >
            {props.data.fileName}
          </div>
          <div
            className="flex-basis-column"
            style={{
              alignItems: "end",
            }}
          >
            <a
              onClick={handleCancel}
              className={classNames(
                "bold-name-text",
                "done-font-color",
                isCancellable(),
                props.data.read ? "text-read" : "text-unread"
              )}
            >
              Cancel
            </a>
            <div
              className={classNames(
                "light-name-text",
                "done-font-color",
                props.data.read ? "text-read" : "text-unread"
              )}
            >
              {props.data.percentageCompleted}%
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="uploader-date-time">
          <div
            className={classNames(
              "uploaded-date",
              "done-font-color",
              props.data.read ? "text-read" : "text-unread"
            )}
          >
            {moment(props.data.lastActionAt).format("MMM D, YYYY")}
          </div>
          <div
            className={classNames(
              "uploaded-time",
              "done-font-color",
              props.data.read ? "text-read" : "text-unread"
            )}
          >
            {moment(props.data.lastActionAt).format("hh:mma")}
          </div>
        </div>
      );
    }
  };

  return <div className="flex flex-basis">{getCell()}</div>;
}
export default UploadTableCell;
