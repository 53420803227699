import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { contextMenu } from "react-contexify";
import TextTruncate from "react-text-truncate";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { TAB_ACTION_MENU_ID, TOOL_TIP_TAB } from "../../../../common/constants";
import { TabDnDProps } from "../../../../common/types/dashboard/DashboardUITypes";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectCurrentTab } from "../../../../redux/reducers/dashboardSlice";
import { selectRows } from "../../../../redux/reducers/documentsDataSlice";
import {
  selectMenu,
  setMenu,
  setMenuTriggor,
} from "../../../../redux/reducers/popupSlice";
import { selectTabActionMetadata } from "../../../../redux/reducers/uiSlice";
import "./tab.scss";
import {
  selectClients,
  setClientName,
} from "../../../../redux/reducers/metadataSlice";
import { ClientEntity } from "../../../../common/types/EntityTypes";
import _ from "lodash";

function Tab(props: TabDnDProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const dispatch = useAppDispatch();
  const [isTruncted, setIsTruncated] = useState(false);
  const selectedMenu = useAppSelector(selectMenu);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const clients: ClientEntity[] = useAppSelector(selectClients);
  const currentClient = clients.find((c) => c.id == props.id);
  const currentClientIndex = _.findIndex(clients, {
    id: props.id,
  });

  const [isEditable, setIsEditable] = useState(false);
  const [fieldNewValue, setFieldNewValue] = useState(props.text);

  const setEditable = () => {
    setIsEditable(true);
  };
  const setNonEditable = () => {
    setIsEditable(false);
  };

  const handleDoubleClick = () => {
    setEditable();

    if (inputRef) {
      inputRef.current?.focus();
      setTimeout(() => {
        const inputValue = inputRef.current?.value;
        if (inputValue) {
          inputRef.current?.setSelectionRange(
            inputValue.length,
            inputValue.length
          );
        }
      }, 0);
    }
  };

  function displayMenu(e: React.MouseEvent<HTMLElement>) {
    dispatch(setMenu(TAB_ACTION_MENU_ID));
    dispatch(setMenuTriggor(props.id));
    if (
      e.currentTarget.offsetParent != null &&
      e.currentTarget.offsetParent != undefined
    ) {
      let source = e.currentTarget.getBoundingClientRect();
      contextMenu.show({
        id: selectedMenu,
        props: { tabId: props.id },
        event: e,
        position: {
          x: source.left + source.width,
          y:
            currentClient?.isDefault &&
            (currentClientIndex == 0 || currentClientIndex == clients.length)
              ? source.top - 50
              : source.top - 110,
          // ? source.top - 75
          // : source.top - 175,
        },
      });
    }
    e.stopPropagation();
  }

  const tabActionMetadata = useAppSelector(selectTabActionMetadata);

  const selectedTab = useAppSelector(selectCurrentTab);
  const selectedRows = useAppSelector(selectRows);

  const getUnReadCountForClient = useCallback(() => {
    const tabRows = selectedRows[props.id];
    if (tabRows == undefined || tabRows.length == 0) return 0;
    else {
      return tabRows.filter((tab) => !tab.read).length;
    }
  }, [selectedRows]);

  const handleChange = async (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    if (props.text != newValue) {
      setFieldNewValue(newValue);
      dispatch(setClientName({ id: props.id, name: newValue }));
    }
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onDoubleClick={handleDoubleClick}
      className="tab-editable-value"
    >
      <div
        data-tooltip-id={TOOL_TIP_TAB}
        data-tooltip-content={fieldNewValue}
        data-tooltip-hidden={!isTruncted}
        className={classNames("tab", {
          "selected-tab":
            selectedTab?.toLowerCase() == props?.id?.toLocaleLowerCase(),
          hide: isEditable,
        })}
      >
        <TextTruncate
          containerClassName=""
          line={2}
          text={fieldNewValue}
          onTruncated={() => setIsTruncated(true)}
        ></TextTruncate>
        <div className="tab-name-unread-count">
          <span
            className={classNames(
              "unread-count",
              selectedTab?.toLowerCase() == props?.id?.toLocaleLowerCase()
                ? "unread-count-selected"
                : "unread-count-unselected",
              getUnReadCountForClient() == 0
                ? "visible-hidden"
                : "visibilty-not-hiddent"
            )}
          >
            {getUnReadCountForClient()}
          </span>
          <img
            width={tabActionMetadata.widthPX}
            height={tabActionMetadata.heightPX}
            onClick={(event) => displayMenu(event)}
            src={
              selectedMenu == TAB_ACTION_MENU_ID
                ? tabActionMetadata.hide
                : tabActionMetadata.show
            }
          ></img>
        </div>

        {/* <div>{props.text}</div> */}
      </div>
      <input
        ref={inputRef}
        onBlur={setNonEditable}
        type="text"
        value={fieldNewValue}
        onChange={(event) => handleChange(event)}
        className={classNames({
          hide: !isEditable,
        })}
        style={{ height: "inherit" }}
      ></input>
      <Tooltip className="tab-tooltip" id={TOOL_TIP_TAB}></Tooltip>
    </div>
  );
}
export default Tab;
