import _, { uniqueId } from "lodash";
import toPX from "to-px";
import { getBlankDocumentData } from "../components/content/table/table-util";
import {
  CLIENT_DUMMY_ROW_IDS,
  CSS_CUSTOM_VARIABLES,
  DEFAULT_NEW_TAB_NAME,
  IMAGE_SUB_TYPE,
  IMAGE_TYPE,
  LEASE_STATUS,
  RESOLUTION_TYPE,
  RESOLUTION_TYPE_DIMENSION,
} from "./constants";
import { ClientEntity, ClientServerDTO } from "./types/EntityTypes";
import { DocumentEntity } from "./types/EntityTypes";
import { ResolutionMetadata } from "./types/dashboard/DashboardUITypes";

export function getImage(
  width: number,
  height: number,
  imageType: String,
  imageSubType: String
) {
  const resDimensionType = getDimensionForResolution(width, height);
  switch (imageType) {
    case IMAGE_TYPE.STATUS:
      switch (imageSubType) {
        case IMAGE_SUB_TYPE.STATUS_IN_PROGRESS:
          return resDimensionType.image_dimension.STATUS.inProgressImage;
        case IMAGE_SUB_TYPE.STATUS_COMPLETED:
          return resDimensionType.image_dimension.STATUS.completedImage;
        case IMAGE_SUB_TYPE.STATUS_HEADER:
          return resDimensionType.image_dimension.STATUS.headerImage;
        default:
          return resDimensionType.image_dimension.STATUS.loisImage;
      }
    case IMAGE_TYPE.UPLOAD:
      return resDimensionType.image_dimension.UPLOAD_DIALOG_LOGO.image;
    case IMAGE_TYPE.DASHBOARD:
      return resDimensionType.image_dimension.DASHBOARD_LOGO.image;
    case IMAGE_TYPE.COLUMN_PIN:
      return;
    case IMAGE_TYPE.ROW_PIN:
      switch (imageSubType) {
        case IMAGE_SUB_TYPE.ROW_PINNED:
          return resDimensionType.image_dimension.ROW_PIN.pinnedImage;
        default:
          return resDimensionType.image_dimension.ROW_PIN.unpinnedImage;
      }
    case IMAGE_TYPE.SETTINGS:
      return resDimensionType.image_dimension.SETTINGS.image;
    case IMAGE_TYPE.NOTES:
      switch (imageSubType) {
        case IMAGE_SUB_TYPE.HIDE:
          return resDimensionType.image_dimension.NOTES.hide;
        default:
          return resDimensionType.image_dimension.NOTES.show;
      }
    case IMAGE_TYPE.CHILDREN:
      switch (imageSubType) {
        case IMAGE_SUB_TYPE.HIDE:
          return resDimensionType.image_dimension.CHILDREN.hide;
        default:
          return resDimensionType.image_dimension.CHILDREN.show;
      }
  }
}

export function getImageSize(
  width: number,
  height: number,
  imageType: String,
  isRem: boolean = false
) {
  const resDimensionType = getDimensionForResolution(width, height);
  let rWidth: any = "",
    rHeight: any = "";

  switch (imageType) {
    case IMAGE_TYPE.STATUS:
      rWidth = isRem
        ? resDimensionType.image_dimension.STATUS.width
        : resDimensionType.image_dimension.STATUS.widthPX;
      rHeight = isRem
        ? resDimensionType.image_dimension.STATUS.height
        : resDimensionType.image_dimension.STATUS.heightPX;
      break;
    case IMAGE_TYPE.UPLOAD:
      rWidth = isRem
        ? resDimensionType.image_dimension.UPLOAD_DIALOG_LOGO.width
        : resDimensionType.image_dimension.UPLOAD_DIALOG_LOGO.widthPX;
      rHeight = isRem
        ? resDimensionType.image_dimension.UPLOAD_DIALOG_LOGO.height
        : resDimensionType.image_dimension.UPLOAD_DIALOG_LOGO.heightPX;
      break;
    case IMAGE_TYPE.DASHBOARD:
      rWidth = isRem
        ? resDimensionType.image_dimension.DASHBOARD_LOGO.width
        : resDimensionType.image_dimension.DASHBOARD_LOGO.widthPX;
      rHeight = isRem
        ? resDimensionType.image_dimension.DASHBOARD_LOGO.height
        : resDimensionType.image_dimension.DASHBOARD_LOGO.heightPX;
      break;
    case IMAGE_TYPE.COLUMN_PIN:
      rWidth = isRem
        ? resDimensionType.image_dimension.COLUMN_PIN.width
        : resDimensionType.image_dimension.COLUMN_PIN.widthPX;
      rHeight = isRem
        ? resDimensionType.image_dimension.COLUMN_PIN.height
        : resDimensionType.image_dimension.COLUMN_PIN.heightPX;
      break;
    case IMAGE_TYPE.ROW_PIN:
      rWidth = isRem
        ? resDimensionType.image_dimension.ROW_PIN.width
        : resDimensionType.image_dimension.ROW_PIN.widthPX;
      rHeight = isRem
        ? resDimensionType.image_dimension.ROW_PIN.height
        : resDimensionType.image_dimension.ROW_PIN.heightPX;
      break;
    case IMAGE_TYPE.SETTINGS:
      rWidth = isRem
        ? resDimensionType.image_dimension.SETTINGS.width
        : resDimensionType.image_dimension.SETTINGS.widthPX;
      rHeight = isRem
        ? resDimensionType.image_dimension.SETTINGS.height
        : resDimensionType.image_dimension.SETTINGS.heightPX;
      break;
    case IMAGE_TYPE.NOTES:
      rWidth = isRem
        ? resDimensionType.image_dimension.NOTES.width
        : resDimensionType.image_dimension.NOTES.widthPX;
      rHeight = isRem
        ? resDimensionType.image_dimension.NOTES.height
        : resDimensionType.image_dimension.NOTES.heightPX;
      break;
    case IMAGE_TYPE.CHILDREN:
      rWidth = isRem
        ? resDimensionType.image_dimension.CHILDREN.width
        : resDimensionType.image_dimension.CHILDREN.widthPX;
      rHeight = isRem
        ? resDimensionType.image_dimension.CHILDREN.height
        : resDimensionType.image_dimension.CHILDREN.heightPX;
      break;
  }

  if (isRem) {
    let pxW = toPX(rWidth);
    let pxH = toPX(rHeight);
    return {
      width: pxW == null ? 0 : pxW,
      height: pxH == null ? 0 : pxH,
    };
  } else {
    return {
      width: rWidth,
      height: rHeight,
    };
  }
}

export function getDimensionForResolution(width: number, height: number) {
  // Get the resolution type
  const resType = getResolutionType({ width: width, height: height });

  // Get the dimension for the resolution
  return getDimensionForResolutionType(resType);
}

// Get Dimension object as per the resolution type
function getDimensionForResolutionType(resType: String) {
  switch (resType) {
    case RESOLUTION_TYPE.HD_MEDIUM_1:
      return RESOLUTION_TYPE_DIMENSION.HD_MEDIUM_1;
    case RESOLUTION_TYPE.HD_MEDIUM_2:
      return RESOLUTION_TYPE_DIMENSION.HD_MEDIUM_2;
    case RESOLUTION_TYPE.HD:
      return RESOLUTION_TYPE_DIMENSION.HD;
    case RESOLUTION_TYPE.FULL_HD:
      return RESOLUTION_TYPE_DIMENSION.FULL_HD;
    case RESOLUTION_TYPE.QHD:
      return RESOLUTION_TYPE_DIMENSION.QHD;
    default:
      return RESOLUTION_TYPE_DIMENSION.HD_LOW;
  }
}

// Get Resolution Type based on the screen width and height
function getResolutionType(dim: { width: number; height: number }) {
  if (
    dim.width > RESOLUTION_TYPE_DIMENSION.QHD.width ||
    dim.height > RESOLUTION_TYPE_DIMENSION.QHD.height
  ) {
    return RESOLUTION_TYPE.QHD;
  } else if (
    dim.width > RESOLUTION_TYPE_DIMENSION.FULL_HD.width ||
    dim.height > RESOLUTION_TYPE_DIMENSION.FULL_HD.height
  ) {
    return RESOLUTION_TYPE.FULL_HD;
  } else if (
    dim.width > RESOLUTION_TYPE_DIMENSION.HD.width ||
    dim.height > RESOLUTION_TYPE_DIMENSION.FULL_HD.height
  ) {
    return RESOLUTION_TYPE.HD;
  } else if (dim.width > RESOLUTION_TYPE_DIMENSION.HD_MEDIUM_1.width) {
    if (dim.height > RESOLUTION_TYPE_DIMENSION.HD_MEDIUM_1.height)
      return RESOLUTION_TYPE.HD_MEDIUM_1;
    else return RESOLUTION_TYPE.HD_MEDIUM_2;
  } else {
    return RESOLUTION_TYPE.HD_LOW;
  }
}

export function getDummyRows(noOfRows: number, noOfValue: number) {
  return _.range(noOfRows).map((r) => {
    return {
      id: uniqueId(),
      name: "",
      status: LEASE_STATUS.DUMMY,
      notes: "",
      market: "",
      author: "",
      values: getBlankDocumentData(noOfValue),
    } as DocumentEntity;
  });
}

export function setupLocalStorageWithDummyRows(
  sortedClients: ClientEntity[],
  noOfColumns: number
) {
  if (sortedClients) {
    const localDummyRows = localStorage.getItem(CLIENT_DUMMY_ROW_IDS);

    // If for the first time the local storage has not data
    if (localDummyRows == null) {
      const dummyRows = sortedClients
        .map((client) => {
          return {
            [client.id]: getDummyRows(50, noOfColumns),
          };
        })
        .reduce((target, source) => Object.assign(target, source), {});
      localStorage.setItem(CLIENT_DUMMY_ROW_IDS, JSON.stringify(dummyRows));
    } else {
      // if the local storage has entry but no data
      const dummyRows = JSON.parse(localDummyRows);
      if (Object.keys(dummyRows).length < 0) {
        const dummyRows = sortedClients
          .map((client) => {
            return {
              [client.id]: getDummyRows(50, noOfColumns),
            };
          })
          .reduce((target, source) => Object.assign(target, source), {});
        localStorage.setItem(CLIENT_DUMMY_ROW_IDS, JSON.stringify(dummyRows));
      } else {
        // if there are any new client added recently
        const newClientsDummyRows = sortedClients
          .filter((client) => Object.keys(dummyRows).indexOf(client.id) < 0)
          .map((client) => {
            return {
              [client.id]: getDummyRows(50, noOfColumns),
            };
          })
          .reduce((target, source) => Object.assign(target, source), {});

        localStorage.setItem(
          CLIENT_DUMMY_ROW_IDS,
          JSON.stringify({ ...dummyRows, ...newClientsDummyRows })
        );
      }
    }
  }
}

export function getDefaultTab(tabIndex: number) {
  const newClient = {} as ClientEntity;
  newClient.id = uniqueId();
  newClient.alias = [];
  newClient.isCustom = true;
  newClient.name = DEFAULT_NEW_TAB_NAME + tabIndex;
  return newClient;
}

export function adjustUIParametersForScreenSizeChange(
  metadata: ResolutionMetadata,
  currentWidth: number,
  currentHeight: number
) {
  let newFontSize = 17.11;
  if (currentHeight <= 750) {
    newFontSize = 11.73;
  } else if (currentHeight >= 751 && currentHeight < 1000) {
    newFontSize = 12.53;
  }

  const baseFontSize: number = getCSSVariable(
    CSS_CUSTOM_VARIABLES.BASE_FONT_SIZE
  );
  if (baseFontSize != newFontSize) {
    setCSSVariable(CSS_CUSTOM_VARIABLES.BASE_FONT_SIZE, newFontSize + "px");
  }
}

// let changePer = 0;
// const wDiff = currentWidth - metadata.width;
// const hDiff = currentHeight - metadata.height;

// if (wDiff != 0 && hDiff != 0) {
//   changePer = (wDiff / hDiff) * 100;
// } else {
//   if (wDiff != 0) {
//     changePer = (wDiff / metadata.width) * 100;
//   }

//   if (hDiff != 0) {
//     changePer = (hDiff / metadata.height) * 100;
//   }
// }
// updateUIParameters(metadata, changePer);

export function updateUIParametersOnFont(newFontSize: number) {
  const baseFontSize: number = getCSSVariable(
    CSS_CUSTOM_VARIABLES.BASE_FONT_SIZE_IMPACTED
  );
  if (baseFontSize != newFontSize) {
    setCSSVariable(
      CSS_CUSTOM_VARIABLES.BASE_FONT_SIZE_IMPACTED,
      newFontSize + "px"
    );
    return (newFontSize - baseFontSize) / baseFontSize;
    // if (baseFontSize != 0 && !Number.isNaN(baseFontSize)) {
    //   updateDependentParameters(
    //     ((newFontSize - baseFontSize) / baseFontSize) * 100
    //   );
    // }
  }
  return 0;
}

export function updateDependentParameters(changePer: number) {
  if (!Number.isNaN(changePer)) {
    Object.values(CSS_CUSTOM_VARIABLES)
      .filter(
        (customVariable: string) =>
          ![
            CSS_CUSTOM_VARIABLES.BASE_FONT_SIZE,
            CSS_CUSTOM_VARIABLES.PAGE_HEADER_HEIGHT,
          ].includes(customVariable)
      )
      .forEach((customVariable: string) => {
        let cssVarCurrentValue: number = getCSSVariable(customVariable);
        if (!Number.isNaN(cssVarCurrentValue)) {
          cssVarCurrentValue =
            cssVarCurrentValue + cssVarCurrentValue * changePer;
          setCSSVariable(customVariable, cssVarCurrentValue + "px");
        }
      });
  }
}

function getCSSVariable(cssVar: string) {
  return Number(
    document.documentElement.style.getPropertyValue(cssVar).slice(0, -2)
  );
}

function setCSSVariable(cssVar: string, value: string) {
  document.documentElement.style.setProperty(cssVar, value);
}
