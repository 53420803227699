import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../../common/types/SliceTypes";
import { RootState } from "../store";

const initial: AppState = {
  network: navigator.onLine,
  lastPing: performance.now(),
  binded: false,
  syncRequired: false,
  initialized: false,
};

export const AppSlice = createSlice({
  name: "app",
  initialState: initial,
  reducers: {
    setInitialized: (state, value) => {
      return {
        ...state,
        initialized: value.payload,
      };
    },
    setPing: (state) => {
      return {
        ...state,
        lastPing: performance.now(),
      };
    },
    setBinding: (state, value) => {
      return {
        ...state,
        binded: value.payload,
      };
    },
    setNetwork: (state, value) => {
      return {
        ...state,
        network: value.payload,
      };
    },
  },
});

export const { setPing, setBinding, setNetwork, setInitialized } =
  AppSlice.actions;

export const selectLastPing = (state: RootState) => state.app.lastPing;

export const selectNetwork = (state: RootState) => state.app.network;

export const selectIsAppInitialized = (state: RootState) =>
  state.app.initialized;

export default AppSlice.reducer;
