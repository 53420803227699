import { AXIOS } from "./axios";

// API Variables
export const API_VARIABLE_USER_ID = ":externalUserId";
export const API_VARIABLE_CONNECTION_ID = ":connectionId";
export const API_VARIABLE_SELECTED_CLIENT_ID = ":selectedClientExternalId";
export const API_VARIABLE_SELECTED_DOCUMENT_ID = ":selectedDocumentId";
export const API_VARIABLE_SELECTED_LEASE_STATUS = ":selectedLeaseStatus";

/**
 * Event Source/ SSE
 */
export const EVENTS = AXIOS.defaults.baseURL + "events/";

export const EVENT_SOURCE_OPEN_CONNECTION =
  EVENTS + "connect/" + API_VARIABLE_USER_ID + "/" + API_VARIABLE_CONNECTION_ID;

export const EVENT_SOURCE_CLOSE_CONNECTION =
  EVENTS + "close/" + API_VARIABLE_USER_ID + "/" + API_VARIABLE_CONNECTION_ID;

/*
Fields API
*/
export const FIELDS = AXIOS.defaults.baseURL + "fields/";

export const FIELD_UPDATE_ATTRIBUTES = FIELDS + API_VARIABLE_USER_ID;

/**Settings API */

/**Values API */
export const VALUES = AXIOS.defaults.baseURL + "values/";

export const VALUES_CLIENTS = VALUES + API_VARIABLE_USER_ID;

export const VALUES_CLIENT =
  VALUES + API_VARIABLE_USER_ID + "/" + API_VARIABLE_SELECTED_CLIENT_ID;

export const VALUES_HTML =
  VALUES +
  "html/" +
  API_VARIABLE_SELECTED_DOCUMENT_ID +
  "/" +
  API_VARIABLE_USER_ID;

// End

export const CLIENTS = AXIOS.defaults.baseURL + "client/";
export const GET_CLIENTS = CLIENTS + API_VARIABLE_USER_ID;

export const ABSTRACTED_DOCUMENT_CHILDREN_VALUES =
  AXIOS.defaults.baseURL + "/parent/?/children/values";
export const ABSTRACTED_DOCUMENT_VALUES = AXIOS.defaults.baseURL + "/values/";
export const ABSTRACTED_DOCUMENT_UPDATE_VALUE =
  AXIOS.defaults.baseURL + "/update/fieldValue";
export const ABSTRACTED_DOCUMENT_REVERT_VALUE =
  AXIOS.defaults.baseURL + "/revert/value/?1/field/?2";

export const UPDATE_ABSTRACTED_DOCUMENT_STATUS =
  AXIOS.defaults.baseURL + "status/lease/";

export const AP_COMPLETE_DOCUMENTS =
  AXIOS.defaults.baseURL + "/status/apcomplete";

export const UPDATE_DOCUMENT_PROGRESS = AXIOS.defaults.baseURL + "/progress";

export const DOCUMENT_LEASE_STATUS = AXIOS.defaults.baseURL + "/status/lease";

/**
 * Dashboard APIs
 */
const DASHBOARD_ROOT = "/rows";

// Hide Children
export const ROW_CHILDREN_HIDE =
  AXIOS.defaults.baseURL +
  DASHBOARD_ROOT +
  "/collapse/" +
  API_VARIABLE_SELECTED_DOCUMENT_ID;

// Show Children
export const ROW_CHILDREN_SHOW =
  AXIOS.defaults.baseURL +
  DASHBOARD_ROOT +
  "/expand/" +
  API_VARIABLE_SELECTED_DOCUMENT_ID;

// Pin an Row
export const ROW_PIN =
  AXIOS.defaults.baseURL +
  DASHBOARD_ROOT +
  "/pin/" +
  API_VARIABLE_SELECTED_DOCUMENT_ID;

// Unpin an row
export const ROW_UNPIN =
  AXIOS.defaults.baseURL +
  DASHBOARD_ROOT +
  "/unpin/" +
  API_VARIABLE_SELECTED_DOCUMENT_ID;

// Mark the Document read
export const MARK_ROW_READ =
  AXIOS.defaults.baseURL +
  DASHBOARD_ROOT +
  "/read/" +
  API_VARIABLE_SELECTED_DOCUMENT_ID;

// Mark the Document un read
export const MARK_ROW_UNREAD =
  AXIOS.defaults.baseURL +
  DASHBOARD_ROOT +
  "/unread/" +
  API_VARIABLE_SELECTED_DOCUMENT_ID;

// Update the notes for an document
export const UPDATE_DOCUMENT_NOTES =
  AXIOS.defaults.baseURL + DASHBOARD_ROOT + "/notes/";

// Update the Market for an Document
export const UPDATE_DOCUMENT_MARKET =
  AXIOS.defaults.baseURL +
  DASHBOARD_ROOT +
  "/:documentId/market/:selectedMarketExternalId";

// Update the author for the document
export const UPDATE_DOCUMENT_AUTHOR =
  AXIOS.defaults.baseURL +
  DASHBOARD_ROOT +
  "/:documentId/author/:authorExternalId";

/*
 *Documents API
 */
// Upload

// Abort Download
export const ABORT_DOCUMENT_UPLOAD =
  AXIOS.defaults.baseURL + "/document/upload/cancel";

// Upload Document
export const UPLOAD_DOCUMENT = AXIOS.defaults.baseURL + "/document/upload/";

// Delete Document
export const REMOVE_DOCUMENT = AXIOS.defaults.baseURL + "/document/delete";

/*
 *Status API
 */
// Get the status for all the documents
export const DOCUMENTS_PROCESS_STATUS =
  AXIOS.defaults.baseURL + "/documents/process/status/";

export const UPDATE_DOCUMENT_LEASE_STATUS =
  AXIOS.defaults.baseURL +
  "/document/" +
  API_VARIABLE_SELECTED_DOCUMENT_ID +
  "/lease/" +
  API_VARIABLE_SELECTED_LEASE_STATUS;

/*
 *Markets API
 */

// Fetch the markets
export const GET_MARKETS = AXIOS.defaults.baseURL + "/market";

/*
 *Author API
 */

// Get the Authors
export const GET_AUTHORS = AXIOS.defaults.baseURL + "/authors";
