import classNames from "classnames";
import Popup from "reactjs-popup";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  selectAbstraction,
  selectIsSettings,
  selectShow,
  selectUploader,
  setClose,
} from "../../redux/reducers/popupSlice";
import Edit from "./edit/Edit";
import "./dialog.scss";
import Settings from "./settings/Settings";
import Upload from "./upload/upload";

const CustomPopup = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector;

  const closePopup = () => {
    dispatch(setClose());
  };

  const isUploadPopup = () => {
    return selector(selectUploader);
  };

  const isAbstraction = () => {
    return selector(selectAbstraction);
  };

  const isSettings = () => {
    return selector(selectIsSettings);
  };

  return (
    <Popup
      className={classNames(
        { "abstracted-values": isAbstraction() },
        { "upload-values": isUploadPopup() },
        { "settings-values": isSettings() }
      )}
      open={useAppSelector(selectShow)}
      onClose={closePopup}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {isUploadPopup() && <Upload key="upload-dialog"></Upload>}
      {isAbstraction() && <Edit key="edit-dialog"></Edit>}
      {isSettings() && <Settings key="settings-dialog"></Settings>}
    </Popup>
  );
};

export default CustomPopup;
