import Dexie, { Table } from "dexie";
import { DB_NAME, DB_NAME_DEV, PROD_ENV } from "../common/constants";
import {
  AuthorOfflineEntity,
  ClientOfflineEntity,
  DocumentOfflineEntity,
  DocumentStatusOfflineEntity,
  FieldOfflineEntity,
  FieldUpdateOfflineEntity,
  MarketOfflineEntity,
  UserOfflineEntity,
} from "../common/types/DB";

export class CustomDexie extends Dexie {
  // Table to hold the Field Values updates
  fieldUpdateValues!: Table<FieldUpdateOfflineEntity>;
  userValues!: Table<UserOfflineEntity>;
  authorValues!: Table<AuthorOfflineEntity>;
  marketValues!: Table<MarketOfflineEntity>;
  clientValues!: Table<ClientOfflineEntity>;
  fieldValues!: Table<FieldOfflineEntity>;
  documents!: Table<DocumentOfflineEntity>;
  documentsStatus!: Table<DocumentStatusOfflineEntity>;

  constructor() {
    super("apdt-" + process.env.REACT_APP_CURRENT_ENV);
    this.version(1).stores({
      fieldValues: "[docId+fieldId]",
    });

    this.version(2).stores({
      fieldValues: "[docId+fieldId]",
      userValues: "userExternalId",
    });

    this.version(3).stores({
      fieldValues: "[docId+fieldId]",
      userValues: "userExternalId",
      authorValues: "externalId",
    });

    this.version(4).stores({
      fieldValues: "[docId+fieldId]",
      userValues: "userExternalId",
      authorValues: "externalId",
      marketValues: "externalId",
    });

    this.version(5).stores({
      fieldValues: "[docId+fieldId]",
      userValues: "userExternalId",
      authorValues: "externalId",
      marketValues: "externalId",
      clientValues: "id",
    });

    this.version(6).stores({
      fieldUpdateValues: "[docId+fieldId]",
      userValues: "userExternalId",
      authorValues: "externalId",
      marketValues: "externalId",
      clientValues: "id",
      fieldValues: "id",
    });

    this.version(7).stores({
      fieldUpdateValues: "[docId+fieldId]",
      userValues: "userExternalId",
      authorValues: "externalId",
      marketValues: "externalId",
      clientValues: "id",
      fieldValues: "code",
      documents: "id,client",
      documentsStatus: "id",
    });

    this.version(8).stores({
      fieldUpdateValues: "[docId+fieldId]",
      userValues: "userExternalId",
      authorValues: "externalId",
      marketValues: "externalId",
      clientValues: "id,viewOrder",
      fieldValues: "code",
      documents: "id,client",
      documentsStatus: "id",
    });

    this.version(9).stores({
      fieldUpdateValues: "docId,fieldId,isPersisted",
      userValues: "userExternalId,isPersisted",
      authorValues: "externalId,isPersisted",
      marketValues: "externalId,isPersisted",
      clientValues: "id,viewOrder,isPersisted",
      fieldValues: "code,isPersisted",
      documents: "id,client,isPersisted",
      documentsStatus: "id,isPersisted",
    });

    this.version(10).stores({
      fieldUpdateValues: "docId,fieldId,isPersisted",
      userValues: "userExternalId,isPersisted",
      authorValues: "externalId,isPersisted",
      marketValues: "externalId,isPersisted",
      clientValues: "id,isDefault,viewOrder,isPersisted",
      fieldValues: "code,isPersisted",
      documents: "id,client,isPersisted",
      documentsStatus: "id,isPersisted",
    });
  }
}

export const db = new CustomDexie();
