import { useEffect, useState } from "react";
import AscBlack from "../../../../assets/images/dialog/upload/sort/AscBlack.svg";
import Asc from "../../../../assets/images/dialog/upload/sort/AscGray.svg";
import DescBlack from "../../../../assets/images/dialog/upload/sort/DescBlack.svg";
import Desc from "../../../../assets/images/dialog/upload/sort/DescGray.svg";
import { CustomUploadHeaderParams } from "../../../../common/types/dashboard/DashboardUITypes";
import "./UploadTableHeader.scss";

function UploadTableHeader(props: CustomUploadHeaderParams) {
  const [sort, setSort] = useState<string>("");
  const ORDER = {
    ASC: "asc",
    DESC: "desc",
  };

  const onSortRequested = (order: "asc" | "desc" | null, event: any) => {
    props.setSort(order);
  };

  useEffect(() => {
    props.column.addEventListener("sortChanged", applySort);
    applySort();
  }, []);

  function applySort() {
    if (props.column.isSortAscending()) {
      setSort(ORDER.ASC);
    } else if (props.column.isSortDescending()) {
      setSort(ORDER.DESC);
    } else {
      setSort("");
    }
  }

  function getImage(type: string) {
    if (sort == "") {
      if (type == ORDER.ASC) {
        return Asc;
      } else {
        return Desc;
      }
    } else if (sort == ORDER.ASC) {
      if (type == "asc") {
        return AscBlack;
      } else {
        return Desc;
      }
    } else {
      if (type == ORDER.ASC) {
        return Asc;
      } else {
        return DescBlack;
      }
    }
  }

  return (
    <div
      className="flex"
      style={{ gap: "var(--custom-uploader-header-text-image-gap)" }}
    >
      <div className="uploader-header-text">{props.type}</div>
      <div
        className="flex-basis-column"
        style={{ gap: "var(--custom-uploader-header-sort-image-gap)" }}
      >
        <img
          src={getImage("asc")}
          onClick={(event) => onSortRequested("asc", event)}
        ></img>
        <img
          src={getImage("desc")}
          onClick={(event) => onSortRequested("desc", event)}
        ></img>
      </div>
    </div>
  );
}
export default UploadTableHeader;
