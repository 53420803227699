import { ClientOfflineEntity } from "../common/types/DB";
import { db } from "./db";

/**
 * Clients DB Action
 */
export const fetchOfflineClients = () => {
  return db.clientValues.orderBy("viewOrder").toArray();
};

export const upsertClients = (values: ClientOfflineEntity[]) => {
  if (values) return db.clientValues.bulkPut(values);
};

export const getLastViewOrder = () => {
  return db.clientValues.orderBy("viewOrder").last();
};

export const insertClient = (value: ClientOfflineEntity) => {
  if (value) {
    return db.clientValues.put(value);
  }
};
