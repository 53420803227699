export class BaseError extends Error {
  constructor(name: string, message: string) {
    super(message);
    this.name = name;
  }
}

export class InitializationError extends BaseError {
  constructor(message: string) {
    super("Initialization Error", message);
  }
}

export class UploadComponentInitializationError extends BaseError {
  constructor(message: string) {
    super("Upload Component Error", message);
  }
}
