import classNames from "classnames";
import { ChangeEvent, useState } from "react";
import { FieldValueMetadata } from "../../../../common/types/dashboard/DashboardUITypes";
import { upsertFieldValues } from "../../../../db/fieldDBAction";
import { useAppSelector } from "../../../../redux/hooks";
import { selectSelectedDocumentId } from "../../../../redux/reducers/dashboardSlice";
import "./EditableValue.scss";

function EditableValue(props: {
  field: FieldValueMetadata;
  isSelected: boolean;
}) {
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);

  const field = { ...props.field };
  const initialValue = field.value;
  const [isEditable, setIsEditable] = useState(false);
  const [fieldNewValue, setFieldNewValue] = useState(field.value);

  const setEditable = () => {
    setIsEditable(true);
  };
  const setNonEditable = () => {
    setIsEditable(false);
  };

  const handleChange = async (
    event: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    if (field.value != newValue) {
      await upsertFieldValues({
        docId: selectedDocumentId,
        isPushed: false,
        fieldId: field.field,
        newValue: newValue,
        oldValue: field.value,
        isPersisted: false,
      });
      setFieldNewValue(newValue);
      field.value = newValue;
    }
  };

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        className={classNames("value", {
          hide: isEditable,
          "value-edited": initialValue != fieldNewValue,
          "value-selected": props.isSelected,
          "value-not-selected": !props.isSelected,
        })}
        onDoubleClick={setEditable}
      >
        {fieldNewValue}
      </div>
      {field.value.length > 50 ? (
        <textarea
          className={classNames("value", {
            hide: !isEditable,
          })}
          rows={5}
          onChange={(event) => handleChange(event)}
          onBlur={setNonEditable}
          value={fieldNewValue}
        ></textarea>
      ) : (
        <input
          onBlur={setNonEditable}
          type="text"
          value={fieldNewValue}
          onChange={(event) => handleChange(event)}
          className={classNames("value", {
            hide: !isEditable,
          })}
        ></input>
      )}
    </>
  );
}
export default EditableValue;
