import { DocumentStatusOfflineEntity } from "../common/types/DB";
import { db } from "./db";

/**
 *  Document Status Action
 */
export const fetchOfflineDocumentsStatus = () => {
  return db.documentsStatus.toArray();
};

// Insert or update an single document
export const upsertDocumentStatus = (value: DocumentStatusOfflineEntity) => {
  if (value) return db.documentsStatus.put(value);
};

// Bulk insert the document status
export const upsertDocumentsStatus = (
  values: DocumentStatusOfflineEntity[]
) => {
  if (values) return db.documentsStatus.bulkPut(values);
};
