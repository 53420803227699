import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ABSTRACTED_DOCUMENT_CHILDREN_VALUES,
  ABSTRACTED_DOCUMENT_REVERT_VALUE,
  ABSTRACTED_DOCUMENT_UPDATE_VALUE,
  API_VARIABLE_CONNECTION_ID,
  API_VARIABLE_USER_ID,
  EVENT_SOURCE_OPEN_CONNECTION,
  UPDATE_ABSTRACTED_DOCUMENT_STATUS,
  UPDATE_DOCUMENT_NOTES,
  UPLOAD_DOCUMENT,
} from "../api/api-constants";
import { AXIOS } from "../api/axios";
import {
  NOTIFICATION_COMPONENT_SUB_TYPE,
  NOTIFICATION_COMPONENT_TYPE,
  NOTIFICATION_TYPE,
} from "../common/constants";
import { ClientEntity, NotificationEntity } from "../common/types/EntityTypes";
import {
  FieldValue,
  NotesState,
  Status,
} from "../common/types/dashboard/DashboardFunctionalTypes";
import { setBinding, setPing } from "./reducers/appSlice";
import { setID } from "./reducers/documentSlice";
import { addChildrenColumnValues } from "./reducers/documentsSlice";
import {
  addDocumentStatus,
  updateDocumentPercentage,
  updateDocumentReadStatus,
  updateDocumentStatus,
} from "./reducers/documentsStatusSlice";
import { addNewClient } from "./reducers/metadataSlice";
import { RootState } from "./store";
import {
  findClientIdForDocumentId,
  mapRowPin,
  markDocumentRead,
} from "./reducers/documentsDataSlice";

export const uploadDocument = createAsyncThunk(
  "/document/upload",
  async (file: File, { getState, dispatch }) => {
    const s = getState() as RootState;
    try {
      if (file != undefined) {
        const data = new FormData();
        data.append("file", file);
        const response = await AXIOS.post(UPLOAD_DOCUMENT + s.user.id, data);
        if (response) {
          dispatch(setID(response.data));
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const udpateLeaseStatus = createAsyncThunk(
  "/document/update/lease",
  async (status: Status, { getState, dispatch }) => {
    const s = getState() as RootState;
    try {
      if (status != undefined) {
        const response = await AXIOS.post(
          UPDATE_ABSTRACTED_DOCUMENT_STATUS + status.id + "/" + status.status
        );
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const setDocumentNotes = createAsyncThunk(
  "/document/update/notes",
  async (notes: NotesState, { getState, dispatch }) => {
    const s = getState() as RootState;
    try {
      if (notes != undefined) {
        const response = await AXIOS.post(
          UPDATE_DOCUMENT_NOTES + notes.id,
          notes.notes,
          { headers: { "Content-Type": "application/json" } }
        );
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const documentChildrenValues = createAsyncThunk(
  "/document/children/values",
  async (parentID: string, { getState, dispatch }) => {
    const s = getState() as RootState;
    try {
      const response = await AXIOS.get(
        ABSTRACTED_DOCUMENT_CHILDREN_VALUES.replace("?", parentID)
      );
      if (response.data?.length > 0) {
        dispatch(addChildrenColumnValues(response.data));
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateDocumentFieldValue = createAsyncThunk(
  "/document/field/value",
  async (field: FieldValue, { getState, dispatch }) => {
    const s = getState() as RootState;
    let url = ABSTRACTED_DOCUMENT_UPDATE_VALUE;
    try {
      const response = await AXIOS.put(url, field);
      if (response.data) {
        // dispatch(setFieldValue(field));
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const revertDocumentFieldValue = createAsyncThunk(
  "/document/field/value/revert",
  async (field: FieldValue, { getState, dispatch }) => {
    const s = getState() as RootState;
    let url = ABSTRACTED_DOCUMENT_REVERT_VALUE.replace(
      "?1",
      field.docId
    ).replace("?2", field.fieldCode);
    try {
      const response = await AXIOS.put(url);
      if (response.data) {
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const sse = createAsyncThunk(
  "/sse",
  async (connectionId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    const url = EVENT_SOURCE_OPEN_CONNECTION.replace(
      API_VARIABLE_USER_ID,
      state.user.id
    ).replace(API_VARIABLE_CONNECTION_ID, connectionId);

    const eventHandler = new EventSource(url, { withCredentials: true });

    eventHandler.addEventListener("PING", (event: MessageEvent) => {
      dispatch(setPing());
    });

    eventHandler.addEventListener(NOTIFICATION_TYPE.ADD, (event) => {
      const notification: NotificationEntity = JSON.parse(event.data);

      switch (notification.type) {
        case NOTIFICATION_COMPONENT_TYPE.UPLOAD:
          switch (notification.subType) {
            case NOTIFICATION_COMPONENT_SUB_TYPE.UPLOAD_NEW_DOCUMENT_STATUS:
              dispatch(addDocumentStatus(notification.data));
              break;
          }
          break;
        case NOTIFICATION_COMPONENT_TYPE.TAB:
          const tab = {
            id: notification.data.id,
            isCustom: notification.data.custom,
            isDefault: notification.data.default,
            name: notification.data.name,
            viewOrder: notification.data.viewOrder,
            alias: notification.data.alias,
          } as ClientEntity;
          dispatch(addNewClient(tab));
          break;
      }
    });

    eventHandler.addEventListener(NOTIFICATION_TYPE.UPDATE, (event) => {
      const notification: NotificationEntity = JSON.parse(event.data);

      switch (notification.type) {
        case NOTIFICATION_COMPONENT_TYPE.UPLOAD:
          switch (notification.subType) {
            case NOTIFICATION_COMPONENT_SUB_TYPE.UPLOAD_DOCUMENT_AP_STATUS:
              dispatch(updateDocumentStatus(notification.data));
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.UPLOAD_DOCUMENT_PERCENTAGE:
              dispatch(updateDocumentPercentage(notification.data));
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUEMENT_READ_STATUS:
              dispatch(updateDocumentReadStatus(notification.data));
          }
          break;
        case NOTIFICATION_COMPONENT_TYPE.DASHBOARD:
          switch (notification.subType) {
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUEMENT_READ_STATUS:
              const clientForRead = findClientIdForDocumentId(state)(
                notification.data["value0"]
              );
              if (clientForRead) {
                dispatch(
                  markDocumentRead({
                    clientId: clientForRead,
                    documentId: notification.data["value0"],
                  })
                );
              }
              break;
            case NOTIFICATION_COMPONENT_SUB_TYPE.DOCUMENT_PINNED_STATUS:
              const clientForPinned = findClientIdForDocumentId(state)(
                notification.data["value0"]
              );
              if (clientForPinned) {
                dispatch(
                  mapRowPin({
                    clientId: clientForRead,
                    documentId: notification.data["value0"],
                    status: notification.data["value1"],
                  })
                );
              }
              break;
          }
      }
    });

    eventHandler.onopen = () => dispatch(setBinding(true));
  }
);
