import { useWindowSize } from "@react-hook/window-size/throttled";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import { contextMenu } from "react-contexify";
import { EditTextarea } from "react-edit-text";
import TextTruncate from "react-text-truncate";
import {
  AUTHOR,
  AUTHOR_MENU_ID,
  CUSTOM_COLUMN_KEY,
  DEFAULT_AUTHOR_NAME,
  IMAGE_SUB_TYPE,
  IMAGE_TYPE,
  LEASE_STATUS,
  MARKET_MENU_ID,
  MENU_SUB_TYPE,
  STATUS_MENU_ID,
} from "../../../../common/constants";
import { DocumentFieldValuesEntity } from "../../../../common/types/EntityTypes";
import { CustomCellParams } from "../../../../common/types/dashboard/DashboardUITypes";
import { getImage, getImageSize } from "../../../../common/util";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  selectCurrentTab,
  selectSelectedDocumentId,
  setDeSelectDocument,
  setDocumentCollapsed,
  setDocumentExpanded,
  setSelectedDocument,
  updateRowChildrenViewStatus,
  updateRowPinStatus,
  updateRowReadStatus,
} from "../../../../redux/reducers/dashboardSlice";
import "../Table.scss";
import {
  selectAuthors,
  selectMarkets,
} from "../../../../redux/reducers/metadataSlice";
import { selectMenu, setMenu } from "../../../../redux/reducers/popupSlice";
import { setDocumentNotes } from "../../../../redux/thunks";
import { toggleRowPin } from "../../../../redux/reducers/documentsDataSlice";

function TableCell(props: CustomCellParams) {
  const [width, height] = useWindowSize();

  const dispatch = useAppDispatch();
  const authors = useAppSelector(selectAuthors);
  const markets = useAppSelector(selectMarkets);
  const selectedTab = useAppSelector(selectCurrentTab);
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  const [isNotesEditable, setNotesEditable] = useState(false);

  const selectedRowId = useAppSelector(selectSelectedDocumentId);
  const selectedMenu = useAppSelector(selectMenu);

  function getAuthor(authorId: any) {
    let selectedAuthor = authors?.find(
      (author) => authorId == author.externalId
    );
    if (selectedAuthor == null || selectedAuthor == undefined) {
      selectedAuthor = authors?.find(
        (author) => author.name == DEFAULT_AUTHOR_NAME
      );
    }

    return selectedAuthor?.name;
  }

  function getMarkets(marketId: any) {
    let selectedMarket = markets?.find(
      (market) => marketId == market.externalId
    );

    if (selectedMarket == null || selectedMarket == undefined) {
      selectedMarket = markets?.find(
        (market) => market.name == DEFAULT_AUTHOR_NAME
      );
    }
    return selectedMarket?.name;
  }

  function displayMenu(
    e: React.MouseEvent<HTMLElement>,
    data: string,
    menuType: string
  ) {
    if (
      e.currentTarget.offsetParent != null &&
      e.currentTarget.offsetParent != undefined
    ) {
      let parentDoc = e.currentTarget.offsetParent?.getBoundingClientRect();
      if (menuType == MENU_SUB_TYPE.STATUS) {
        dispatch(setMenu(STATUS_MENU_ID));
      } else if (menuType == MENU_SUB_TYPE.AUTHOR) {
        dispatch(setMenu(AUTHOR_MENU_ID));
      } else if (menuType == MENU_SUB_TYPE.MARKET) {
        dispatch(setMenu(MARKET_MENU_ID));
      }
      contextMenu.show({
        id: selectedMenu,
        event: e,
        props: { data },
        position: {
          x: parentDoc.left + parentDoc.width + 5,
          y: parentDoc.top + 5,
        },
      });
    }
  }

  function handleSave(input: {
    name: string;
    value: string;
    previousValue: string;
  }): void {
    if (input.value !== input.previousValue) {
      dispatch(
        setDocumentNotes({
          id: props.data.id,
          notes: input.value,
        })
      );
    }
  }

  function handleRowPin(event: React.MouseEvent<HTMLImageElement>) {
    if (!props.data.locked) {
      dispatch(setSelectedDocument(props.data));
    } else {
      if (selectedDocumentId == props.data.id) {
        dispatch(setDeSelectDocument());
      }
    }
    // Update the Redux State
    dispatch(
      toggleRowPin({
        clientId: selectedTab,
        documentId: props.data.id,
      })
    );
    dispatch(updateRowPinStatus(!props.data.locked));
  }

  function handleChildren(event: React.MouseEvent<HTMLImageElement>) {
    if (props.data.expanded) {
      dispatch(setDocumentCollapsed(props.data.id));
    } else {
      dispatch(setDocumentExpanded(props.data.id));
    }
    dispatch(setSelectedDocument(props.data));
    dispatch(updateRowChildrenViewStatus(!props.data.expanded));
  }

  function getData() {
    const headerKey = props.customData?.code;
    let maxNoOfLines = 10,
      minNoOfLines = 2;

    if (props.data.status == LEASE_STATUS.DUMMY) {
      return "";
    }

    if (headerKey == CUSTOM_COLUMN_KEY.PIN) {
      const isLocked = props.data.locked;
      const dim = getImageSize(width, height, IMAGE_TYPE.ROW_PIN);
      const src = getImage(
        width,
        height,
        IMAGE_TYPE.ROW_PIN,
        isLocked ? IMAGE_SUB_TYPE.ROW_UNPINNED : IMAGE_SUB_TYPE.ROW_PINNED
      );
      return (
        <div className="flex  fit-container flex-align-center">
          <img
            width={dim.width}
            height={dim.height}
            src={src}
            onClick={(event) => handleRowPin(event)}
          ></img>
        </div>
      );
    } else if (headerKey == CUSTOM_COLUMN_KEY.MARKET) {
      return (
        <div
          className={classNames(
            "fit",
            "fit-container",
            props.data.read ? "text-read" : "text-unread",
            "ag-cell-font-common"
          )}
          onClick={(e) => displayMenu(e, props.data, MENU_SUB_TYPE.MARKET)}
        >
          {getMarkets(props.data.market)}
        </div>
      );
    } else if (headerKey == CUSTOM_COLUMN_KEY.AUTHOR) {
      const author = getAuthor(props.data.author);
      return (
        <div
          onClick={(e) => displayMenu(e, props.data, MENU_SUB_TYPE.AUTHOR)}
          className={classNames(
            "fit",
            "fit-container",
            // "bold-text",
            props.data.read ? "text-read" : "text-unread",
            "loi-status-font-color"
            /*{
              "author-landlord-color":
                AUTHOR.LANDLORD.toLowerCase() == author?.toLowerCase(),
            },
            {
              "author-tenant-color":
                AUTHOR.TENANT.toLowerCase() == author?.toLowerCase(),
            },
            {
              "author-no-color":
                AUTHOR.SELECT_AUTHOR.toLowerCase() == author?.toLowerCase(),
            }*/
          )}
        >
          {author}
        </div>
      );
    } else if (headerKey == CUSTOM_COLUMN_KEY.NOTES) {
      const notes =
        props.data.notes == null || props.data.notes == ""
          ? "Fill in your Long long long notes"
          : props.data.notes;

      return (
        <EditTextarea
          inputClassName={classNames(
            "ag-cell-font-common",
            props.data.read ? "text-read" : "text-unread"
          )}
          className={classNames(props.data.read ? "text-read" : "text-unread")}
          rows={selectedRowId == props.data.id ? maxNoOfLines : minNoOfLines}
          defaultValue={notes}
          onSave={handleSave}
        ></EditTextarea>
      );
    } else if (headerKey == CUSTOM_COLUMN_KEY.CHILDREN) {
      const expanded = props.data.expanded;
      const dim = getImageSize(width, height, IMAGE_TYPE.CHILDREN);
      return (
        <div className="flex  fit-container flex-align-center">
          <img
            className={classNames(
              { "visibilty-not-hiddent": props.data.parent == "" },
              { "visible-hidden": props.data.parent != "" }
            )}
            width={dim.width}
            height={dim.height}
            src={getImage(
              width,
              height,
              IMAGE_TYPE.CHILDREN,
              expanded ? IMAGE_SUB_TYPE.HIDE : IMAGE_SUB_TYPE.SHOW
            )}
            onClick={(event) => handleChildren(event)}
          ></img>
        </div>
      );
    } else if (headerKey == CUSTOM_COLUMN_KEY.STATUS) {
      const dim = getImageSize(width, height, IMAGE_TYPE.STATUS);
      const state = props.data.status;
      let imgSrc = getImage(width, height, IMAGE_TYPE.STATUS, state);
      return (
        <div
          className={classNames(
            "flex",
            "fit-container",
            " flex-align-center",
            {
              "status-active-color":
                state.toLowerCase() == LEASE_STATUS.ACTIVE.toLowerCase(),
            },
            {
              "status-in-active-color":
                state.toLowerCase() == LEASE_STATUS.IN_LEASE.toLowerCase(),
            },
            {
              "status-dead-deal-color":
                state.toLowerCase() == LEASE_STATUS.DEAD_DEAL.toLowerCase(),
            },
            "ag-cell-font-common",
            "loi-status-font-weight-parent",
            // "bold-text",
            props.data.read ? "text-read" : "text-unread",
            "loi-status-font-color"
          )}
          onClick={(e) => displayMenu(e, props.data, MENU_SUB_TYPE.STATUS)}
        >
          {_.upperFirst(_.replace(state, "_", " "))}
          {/* <img width={dim.width} height={dim.height} src={imgSrc}></img> */}
        </div>
      );
    }
    const column: DocumentFieldValuesEntity = props.data.values.find(
      (d: DocumentFieldValuesEntity) => d.field == props.customData?.code
    );
    if (column != undefined) {
      let data = column.value;
      if (data == "---") {
        data = "-";
      }
      if (props.customData?.code == "003m") {
        return (
          <div
            className={classNames(
              "ag-cell-font-common",
              props.data.read ? "text-read" : "text-unread"
            )}
          >
            {data}
          </div>
        );
      }
      return (
        <TextTruncate
          containerClassName={classNames(
            "ag-cell-font-common",
            props.data.read ? "text-read" : "text-unread"
          )}
          line={selectedRowId == props.data.id ? maxNoOfLines : minNoOfLines}
          textElement="div"
          text={data}
        />
      );
    }

    return "";
  }
  return <div>{getData()}</div>;
}

export default TableCell;
