// Logo
//1280
import DashboardLogo_1280 from "../assets/images/dashboard/logo/dashboard/1280.svg";
import UploadLogo_1280 from "../assets/images/dialog/upload/logo/1280.svg";
//1366
import DashboardLogo_1366 from "../assets/images/dashboard/logo/dashboard/1366.svg";
import UploadLogo_1366 from "../assets/images/dialog/upload/logo/1366.svg";
//1920
import DashboardLogo_1920 from "../assets/images/dashboard/logo/dashboard/1920.svg";
import UploadLogo_1920 from "../assets/images/dialog/upload/logo/1920.svg";
//2048
import DashboardLogo_2048 from "../assets/images/dashboard/logo/dashboard/2048.svg";
import UploadLogo_2048 from "../assets/images/dialog/upload/logo/2048.svg";

import UserIcon_1280 from "../assets/images/dashboard/header/user/1280.svg";
import UserIcon_1366 from "../assets/images/dashboard/header/user/1366.svg";
import UserIcon_1920 from "../assets/images/dashboard/header/user/1920.svg";
import UserIcon_2048 from "../assets/images/dashboard/header/user/2048.svg";

// Row Pinning
//1280
import PinnedRow_1280 from "../assets/images/dashboard/table/pinned-row/1280.svg";
import UnPinnedRow_1280 from "../assets/images/dashboard/table/unpinned-row/1280.svg";
//1366
import PinnedRow_1366 from "../assets/images/dashboard/table/pinned-row/1366.svg";
import UnPinnedRow_1366 from "../assets/images/dashboard/table/unpinned-row/1366.svg";
//1920
import PinnedRow_1920 from "../assets/images/dashboard/table/pinned-row/1920.svg";
import UnPinnedRow_1920 from "../assets/images/dashboard/table/unpinned-row/1920.svg";
//2048
import PinnedRow_2048 from "../assets/images/dashboard/table/pinned-row/2048.svg";
import UnPinnedRow_2048 from "../assets/images/dashboard/table/unpinned-row/2048.svg";

// Settings
import Settings_1280 from "../assets/images/dashboard/table/settings/1280.svg";
import Settings_1366 from "../assets/images/dashboard/table/settings/1366.svg";
import Settings_1920 from "../assets/images/dashboard/table/settings/1920.svg";
import Settings_2048 from "../assets/images/dashboard/table/settings/2048.svg";

// Tab
import Tab_Active_Left_1280 from "../assets/images/dashboard/table/tab/active/Active_Left_1280.svg";
import Tab_Active_Right_1280 from "../assets/images/dashboard/table/tab/active/Active_Right_1280.svg";
import Tab_Inactive_Left_1280 from "../assets/images/dashboard/table/tab/inactive/Inactive_Left_1280.svg";
import Tab_Inactive_Right_1280 from "../assets/images/dashboard/table/tab/inactive/Inactive_Right_1280.svg";

import Tab_Active_Left_1366 from "../assets/images/dashboard/table/tab/active/Active_Left_1366.svg";
import Tab_Active_Right_1366 from "../assets/images/dashboard/table/tab/active/Active_Right_1366.svg";
import Tab_Inactive_Left_1366 from "../assets/images/dashboard/table/tab/inactive/Inactive_Left_1366.svg";
import Tab_Inactive_Right_1366 from "../assets/images/dashboard/table/tab/inactive/Inactive_Right_1366.svg";

import Tab_Active_Left_1920 from "../assets/images/dashboard/table/tab/active/Active_Left_1920.svg";
import Tab_Active_Right_1920 from "../assets/images/dashboard/table/tab/active/Active_Right_1920.svg";
import Tab_Inactive_Left_1920 from "../assets/images/dashboard/table/tab/inactive/Inactive_Left_1920.svg";
import Tab_Inactive_Right_1920 from "../assets/images/dashboard/table/tab/inactive/Inactive_Right_1920.svg";

import Tab_Active_Left_2048 from "../assets/images/dashboard/table/tab/active/Active_Left_2048.svg";
import Tab_Active_Right_2048 from "../assets/images/dashboard/table/tab/active/Active_Right_2048.svg";
import Tab_Inactive_Left_2048 from "../assets/images/dashboard/table/tab/inactive/Inactive_Left_2048.svg";
import Tab_Inactive_Right_2048 from "../assets/images/dashboard/table/tab/inactive/Inactive_Right_2048.svg";

// Column Pin
import ColumnPin_1280 from "../assets/images/dashboard/table/pin-column/1280.svg";
import ColumnPin_1366 from "../assets/images/dashboard/table/pin-column/1366.svg";
import ColumnPin_1920 from "../assets/images/dashboard/table/pin-column/1920.svg";
import ColumnPin_2048 from "../assets/images/dashboard/table/pin-column/2048.svg";

import ColumnUnPin_1280 from "../assets/images/dashboard/table/unpinned-column/1280.svg";

// Status Images
// 1280
import Completed_1280 from "../assets/images/dashboard/table/status/completed/1280.svg";
import StatusHeader_1280 from "../assets/images/dashboard/table/status/header/1280.svg";
import LOIsWorking_1280 from "../assets/images/dashboard/table/status/lois-working/1280.svg";
//1366
import Completed_1366 from "../assets/images/dashboard/table/status/completed/1366.svg";
import StatusHeader_1366 from "../assets/images/dashboard/table/status/header/1366.svg";
import LOIsWorking_1366 from "../assets/images/dashboard/table/status/lois-working/1366.svg";
//1920
import Completed_1920 from "../assets/images/dashboard/table/status/completed/1920.svg";
import StatusHeader_1920 from "../assets/images/dashboard/table/status/header/1920.svg";
import LOIsWorking_1920 from "../assets/images/dashboard/table/status/lois-working/1920.svg";
// 2048
import Completed_2048 from "../assets/images/dashboard/table/status/completed/2048.svg";
import StatusHeader_2048 from "../assets/images/dashboard/table/status/header/2048.svg";
import LOIsWorking_2048 from "../assets/images/dashboard/table/status/lois-working/2048.svg";

import HideNotes_1280 from "../assets/images/dashboard/table/notes/hide/1280.svg";
import HideNotes_1366 from "../assets/images/dashboard/table/notes/hide/1366.svg";
import HideNotes_1920 from "../assets/images/dashboard/table/notes/hide/1920.svg";
import HideNotes_2048 from "../assets/images/dashboard/table/notes/hide/2048.svg";

import ShowNotes from "../assets/images/dashboard/table/ShowNotes.svg";

// Children
//1280
import HideChildren_1280 from "../assets/images/dashboard/table/hide/1280.svg";
import ShowChildren_1280 from "../assets/images/dashboard/table/show/1280.svg";
//1366
import HideChildren_1366 from "../assets/images/dashboard/table/hide/1366.svg";
import ShowChildren_1366 from "../assets/images/dashboard/table/show/1366.svg";
//1920
import HideChildren_1920 from "../assets/images/dashboard/table/hide/1920.svg";
import ShowChildren_1920 from "../assets/images/dashboard/table/show/1920.svg";
//2048
import HideChildren_2048 from "../assets/images/dashboard/table/hide/2048.svg";
import ShowChildren_2048 from "../assets/images/dashboard/table/show/2048.svg";

import NewTab from "../assets/images/dashboard/table/tab/NewTab.svg";
import TabSelection from "../assets/images/dashboard/table/tab/TabSelection.svg";
import TabHide from "../assets/images/dashboard/table/tab/TabHide.svg";
import TabShow from "../assets/images/dashboard/table/tab/TabShow.svg";
import _, { uniqueId } from "lodash";
import { getBlankDocumentData } from "../components/content/table/table-util";
import { DocumentEntity } from "./types/EntityTypes";

export const SUPPORTED_FILE_FORMATS = ["docx", "doc", "pdf"];

export const CLIENT_NAME_COUNTER_STORAGE_KEY = "client_count";
export const DELETE_ACTION_DOC_IDS = "FILTER_DOC_IDS";
export const CLIENT_DUMMY_ROW_IDS = "DUMMY_ROW_IDS";
// Menu IDs don't delete
export const NONE_MENU_ID = "none-menu-id";
export const MARKET_MENU_ID = "market-menu-id";
export const AUTHOR_MENU_ID = "author-menu-id";
export const STATUS_MENU_ID = "status-menu-id";
export const STATUS_FILTER_MENU_ID = "status-filter-menu-id";

export const TAB_ACTION_MENU_ID = "tab-action-menu-id";
export const TAB_VIEW_MENU_ID = "tab-view-menu-id";
// Menu IDs

// DBs Constants
export const PROD_ENV = "prod";
export const DEV_ENV = "dev";

export const DB_NAME = "apdt";
export const DB_NAME_DEV = "apdt-dev";

export const TOOL_TIP_TAB = "tab-tool-tip";

export const DEFAULT_TAB_NAME = "None";

export const DEFAULT_AUTHOR_NAME = "Select Author";

export const DEFAULT_MARKET = "No Market";

export const DEFAULT_NEW_TAB_NAME = "New Client";

export const NOTIFICATION_TYPE = {
  PING: "PING",
  ADD: "ADD",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const NOTIFICATION_COMPONENT_TYPE = {
  UPLOAD: "UPLOAD",
  DASHBOARD: "DASHBOARD",
  TAB: "TAB",
  EDIT: "EDIT",
};

export const NOTIFICATION_COMPONENT_SUB_TYPE = {
  UPLOAD_NEW_DOCUMENT_STATUS: "UPLOAD_NEW_DOCUMENT_STATUS",
  UPLOAD_DOCUMENT_PERCENTAGE: "UPLOAD_DOCUMENT_PERCENTAGE",
  UPLOAD_DOCUMENT_AP_STATUS: "UPLOAD_DOCUMENT_AP_STATUS",
  NEW_DOCUMENT: "NEW_DOCUMENT",
  TAB: "TAB",
  EDIT: "EDIT",
  DOCUEMENT_READ_STATUS: "DOCUEMENT_READ_STATUS",
  DOCUMENT_PINNED_STATUS: "DOCUMENT_PINNED_STATUS",
};

export const SETTINGS_TAB_NAME = {
  GENERAL: "General Settings",
  COLUMNS: "Column Labels/ Order",
  ROWS: "Rows Sort Order",
  TAB: "Tab Name/ Aliases",
  MARKET: "Market/ Trade Area",
};

export const TAB_ACTIONS = {
  DELETE: "Delete",
  RENAME: "Rename",
  MOVE_LEFT: "Move Left",
  MOVE_RIGHT: "Move Right",
  SET_DEFAULT: "Set Default",
};

export const SETTINGS_TAB_HEADER_SUB_TEXT = {
  MARKET: "(Double-click to rename Market / Trade Area)",
};

export const MENU_TYPE = {
  TABLE: "TABLE",
  TAB: "TAB",
};

export const MENU_SUB_TYPE = {
  MARKET: "MARKET",
  AUTHOR: "AUTHOR",
  STATUS: "STATUS",
  HEADER_STATUS_FILTER: "HEADER_STATUS_FILTER",
  TAB_ACTION: "TAB_ACTION",
  TAB_VIEW: "TAB_VIEW",
  STATUS_HEADER: "STATUS_HEADER",
  CELL: "CELL",
  NOTES: "NOTES",
};

export const CSS_CUSTOM_VARIABLES = {
  BASE_FONT_SIZE: "--custom-font-size", // dont include
  BASE_FONT_SIZE_IMPACTED: "--custom-user-impacted-font-size",
  PAGE_HEADER_HEIGHT: "--custom-page-header-height", // dont include in param update
  PAGE_HEADER_BORDER_THICKNESS: "--custom-page-header-border-thickness",
  PAGE_HEADER_PADDING: "--custom-page-header-padding",
  PAGE_HEADER_UPLOAD_BUTTON_FONT_SIZE:
    "--custom-page-header-upload-button-font-size",
};

export const UPLOADER_POPUP_COLUMN_NAMES = {
  ID: "ID",
  STATUS: "STATUS",
  DOCUMENT_NAME: "DOCUMENT_NAME",
  AUTHOR: "AUTHOR",
  UPLOADED: "UPLOADED",
  PERCENTAGE_COMPLETED: "PERCENTAGE_COMPLETED",
};

export const BACKEND_AP_STATUS = {
  UPLOADING: "UPLOADING",
  IN_PROGRESS: "IN_PROGRESS",
  AP_COMPLETE: "AP_COMPLETE",
  ABSTRACTING: "ABSTRACTING",
  CONVERTING: "CONVERTING",
  UPLOADED: "UPLOADED",
  HIGHLIGHTING: "HIGHLIGHTING",
};

export const AP_STATUS = {
  NONE: "NONE",
  UPLOADING: "UPLOADING",
  IN_PROGRESS: "IN_PROGRESS",
  ABSTRACTING: "ABSTRACTING",
  DONE: "DONE",
};

export const LEASE_STATUS = {
  DUMMY: "DUMMY",
  NONE: "NONE",
  ACTIVE: "ACTIVE",
  IN_LEASE: "IN_LEASE",
  DEAD_DEAL: "DEAD_DEAL",
};

export const AUTHOR = {
  SELECT_AUTHOR: "SELECT AUTHOR",
  TENANT: "TENANT",
  LANDLORD: "LANDLORD",
};

export const RESOLUTION_TYPE = {
  NONE: "NONE",
  HD_LOW: "HD_LOW",
  HD_MEDIUM_1: "HD_MEDIUM_1",
  HD_MEDIUM_2: "HD_MEDIUM_2",
  HD: "HD",
  FULL_HD: "FULL_HD",
  QHD: "QHD",
};

export const TABLE_TYPE = {
  ALL: "ALL",
  LEFT_ACTIONS: "LEFT ACTIONS",
  CONTENT: "CONTENT",
  RIGHT_ACTIONS: "RIGHT ACTIONS",
};

export const IMAGE_TYPE = {
  USER: "USER",
  STATUS: "STATUS",
  ROW_PIN: "ROW_PIN",
  COLUMN_PIN: "COLUMN_PIN",
  SETTINGS: "SETTINGS",
  NOTES: "NOTES",
  CHILDREN: "CHILDREN",
  UPLOAD: "UPLOAD_DIALOG_LOGO",
  DASHBOARD: "DASHBOARD_LOGO",
};

export const IMAGE_SUB_TYPE = {
  SHOW: "SHOW",
  HIDE: "HIDE",
  ROW_PINNED: "ROW_PINNED",
  ROW_UNPINNED: "ROW_UNPINNED",
  STATUS_HEADER: "STATUS_HEADER",
  STATUS_LOIs_WORKING: "LOIs_WORKING",
  STATUS_IN_PROGRESS: "IN_PROGRESS",
  STATUS_COMPLETED: "COMPLETED",
};

export const RESOLUTION_TYPE_DIMENSION = {
  HD_LOW: {
    width: 1024,
    height: 768,
    rowHeightREM: "2.8125rem",
    rowHeight: 45,
    underHeaderHeight: 5,
    uploaderRowHeight: 25.51,
    uploaderHeaderRowHeight: 40,
    padding: 10,
    fontSize: 12.53, //11.743,
    headerLineHeight: 16.44,
    headerGap: 11.743,
    textGap: 10.672,
    scroll_bar: {
      vertical: {
        width: "0.25rem",
        height: "-1",
        lpadding: "0.25rem",
        rpadding: "0.25rem",
        tpadding: "0.3125rem",
        bpadding: "0.3125rem",
        widthPX: 4,
        heightPX: -1,
        lpaddingPX: 8,
        rpaddingPX: 8,
        tpaddingPX: 5,
        bpaddingPX: 5,
      },
      horizontal: {
        width: "-1",
        height: "0.25rem",
        lpadding: "0rem",
        rpadding: "0rem",
        tpadding: "0.5rem",
        bpadding: "0.5rem",
        widthPX: -1,
        heightPX: 4,
        lpaddingPX: 0,
        rpaddingPX: 0,
        tpaddingPX: 8,
        bpaddingPX: 8,
      },
    },
    image_dimension: {
      STATUS: {
        width: "1.10094rem",
        height: "1.46788rem",
        widthPX: 17.615,
        heightPX: 23.486,
        headerImage: StatusHeader_1280,
        loisImage: LOIsWorking_1280,
        inProgressImage: "",
        completedImage: Completed_1280,
      },
      UPLOAD_DIALOG_LOGO: {
        width: "6.75713rem",
        height: "0.93769rem",
        widthPX: 108.114,
        heightPX: 15.003,
        image: UploadLogo_1280,
      },
      USER: {
        width: "1.875rem",
        height: "1.875rem",
        widthPX: 30,
        heightPX: 30,
        image: UserIcon_1280,
      },
      DASHBOARD_LOGO: {
        width: "11.14938rem",
        height: "1.5625rem",
        widthPX: 178.39,
        heightPX: 25,
        image: DashboardLogo_1280,
      },
      COLUMN_PIN: {
        width: "0.43006rem",
        height: "0.7265rem",
        widthPX: 6.881,
        heightPX: 11.624,
        pinnedImage: ColumnPin_1280,
        unpinnedImage: ColumnUnPin_1280,
      },
      ROW_PIN: {
        width: "0.67888rem",
        height: "0.84313rem",
        widthPX: 10.862,
        heightPX: 13.49,
        pinnedImage: PinnedRow_1280,
        unpinnedImage: UnPinnedRow_1280,
      },
      SETTINGS: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 23.484,
        heightPX: 24.828,
        image: Settings_1280,
      },
      NOTES: {
        width: "0.6875rem",
        height: "0.4125rem",
        widthPX: 11,
        heightPX: 6.6,
        show: ShowNotes,
        hide: HideNotes_1280,
      },
      CHILDREN: {
        width: "0.625rem",
        height: "0.875rem",
        widthPX: 10,
        heightPX: 14,
        show: ShowChildren_1280,
        hide: HideChildren_1280,
      },
      TAB_ADD: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 10,
        heightPX: 10,
        image: NewTab,
      },
      TABS_VIEW: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 10,
        heightPX: 10,
        image: TabSelection,
      },
      TAB_ACTION: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 5,
        heightPX: 6,
        show: TabShow,
        hide: TabHide,
      },
      TAB_NAV_LEFT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 6.68,
        heightPX: 10,
        show: Tab_Active_Left_1280,
        hide: Tab_Inactive_Left_1280,
      },
      TAB_NAV_RIGHT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 6.68,
        heightPX: 10,
        show: Tab_Active_Left_1280,
        hide: Tab_Inactive_Right_1280,
      },
    },
    columns_dimension: {
      PIN: {
        width: "1.5625rem",
        widthPX: 25,
      },
      AUTHOR: {
        width: "4.6875rem",
        widthPX: 75,
      },
      STATUS: {
        width: "2.5rem",
        widthPX: 65,
      },
      MARKET: {
        width: "5.9375rem",
        widthPX: 95,
      },
      NOTES: {
        width: "9.6875rem",
        widthPX: 155,
      },
      NOTES_HIDDEN: {
        width: "1rem",
        widthPX: 32,
      },
      CHILDREN: {
        width: "2.5rem",
        widthPX: 45,
      },
      OTHERS: {
        width: "10.3125rem",
        widthPX: 165,
      },
      UPLOAD_STATUS: {
        width: "5.3125rem",
        widthPX: 85,
      },
      UPLOAD_DOCUMENT_NAME: {
        width: "17.8125rem",
        widthPX: 285,
      },
      UPLOAD_UPLOADED: {
        width: "5.625rem",
        widthPX: 90,
      },
    },
  },
  HD_MEDIUM_1: {
    width: 1280,
    height: 720,
    rowHeightREM: "2.8125rem",
    rowHeight: 45,
    underHeaderHeight: 5,
    uploaderRowHeight: 25,
    uploaderHeaderRowHeight: 40,
    padding: 10,
    fontSize: 12.743,
    headerLineHeight: 16.44,
    headerGap: 11.743,
    textGap: 10.672,
    scroll_bar: {
      vertical: {
        width: "0.25rem",
        height: "-1",
        lpadding: "0.25rem",
        rpadding: "0.25rem",
        tpadding: "0.3125rem",
        bpadding: "0.3125rem",
        widthPX: 4,
        heightPX: -1,
        lpaddingPX: 8,
        rpaddingPX: 8,
        tpaddingPX: 5,
        bpaddingPX: 5,
      },
      horizontal: {
        width: "-1",
        height: "0.25rem",
        lpadding: "0rem",
        rpadding: "0rem",
        tpadding: "0.5rem",
        bpadding: "0.5rem",
        widthPX: -1,
        heightPX: 4,
        lpaddingPX: 0,
        rpaddingPX: 0,
        tpaddingPX: 8,
        bpaddingPX: 8,
      },
    },
    image_dimension: {
      STATUS: {
        width: "1.10094rem",
        height: "1.46788rem",
        widthPX: 17.615,
        heightPX: 23.486,
        headerImage: StatusHeader_1280,
        loisImage: LOIsWorking_1280,
        inProgressImage: "",
        completedImage: Completed_1280,
      },
      UPLOAD_DIALOG_LOGO: {
        width: "6.62463rem",
        height: "0.9375rem",
        widthPX: 105.994,
        heightPX: 15,
        image: UploadLogo_1280,
      },
      USER: {
        width: "1.875rem",
        height: "1.875rem",
        widthPX: 30,
        heightPX: 30,
        image: UserIcon_1280,
      },
      DASHBOARD_LOGO: {
        width: "11.14938rem",
        height: "1.5625rem",
        widthPX: 178.39,
        heightPX: 25,
        image: DashboardLogo_1280,
      },
      COLUMN_PIN: {
        width: "0.43006rem",
        height: "0.7265rem",
        widthPX: 6.881,
        heightPX: 11.624,
        pinnedImage: ColumnPin_1280,
        unpinnedImage: ColumnUnPin_1280,
      },
      ROW_PIN: {
        width: "0.67888rem",
        height: "0.84313rem",
        widthPX: 10.862,
        heightPX: 13.49,
        pinnedImage: PinnedRow_1280,
        unpinnedImage: UnPinnedRow_1280,
      },
      SETTINGS: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 23.484,
        heightPX: 24.828,
        image: Settings_1280,
      },
      NOTES: {
        width: "0.6875rem",
        height: "0.4125rem",
        widthPX: 11,
        heightPX: 6.6,
        show: ShowNotes,
        hide: HideNotes_1280,
      },
      CHILDREN: {
        width: "0.625rem",
        height: "0.8125rem",
        widthPX: 10,
        heightPX: 13,
        show: ShowChildren_1280,
        hide: HideChildren_1280,
      },
      TAB_ADD: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 10,
        heightPX: 10,
        image: NewTab,
      },
      TABS_VIEW: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 10,
        heightPX: 10,
        image: TabSelection,
      },
      TAB_ACTION: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 5,
        heightPX: 6,
        show: TabShow,
        hide: TabHide,
      },
      TAB_NAV_LEFT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 6.68,
        heightPX: 10,
        show: Tab_Active_Left_1280,
        hide: Tab_Inactive_Left_1280,
      },
      TAB_NAV_RIGHT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 6.68,
        heightPX: 10,
        show: Tab_Active_Right_1280,
        hide: Tab_Inactive_Right_1280,
      },
    },
    columns_dimension: {
      PIN: {
        width: "1.5625rem",
        widthPX: 25,
      },
      AUTHOR: {
        width: "4.6875rem",
        widthPX: 75,
      },
      STATUS: {
        width: "2.5rem",
        widthPX: 65,
      },
      MARKET: {
        width: "5.9375rem",
        widthPX: 95,
      },
      NOTES: {
        width: "9.6875rem",
        widthPX: 155,
      },
      NOTES_HIDDEN: {
        width: "1rem",
        widthPX: 32,
      },
      CHILDREN: {
        width: "2.5rem",
        widthPX: 45,
      },
      OTHERS: {
        width: "10.3125rem",
        widthPX: 165,
      },
      UPLOAD_STATUS: {
        width: "5.3125rem",
        widthPX: 85,
      },
      UPLOAD_DOCUMENT_NAME: {
        width: "17.8125rem",
        widthPX: 285,
      },
      UPLOAD_UPLOADED: {
        width: "5.625rem",
        widthPX: 90,
      },
    },
  },
  HD_MEDIUM_2: {
    width: 1280,
    height: 800,
    rowHeightREM: "2.8125rem",
    rowHeight: 45,
    underHeaderHeight: 5,
    uploaderRowHeight: 25.51,
    uploaderHeaderRowHeight: 45,
    padding: 10,
    fontSize: 12.53, //11.743,
    headerLineHeight: 16.44,
    headerGap: 11.743,
    textGap: 10.672,
    scroll_bar: {
      vertical: {
        width: "0.25rem",
        height: "-1",
        lpadding: "0.25rem",
        rpadding: "0.25rem",
        tpadding: "0.3125rem",
        bpadding: "0.3125rem",
        widthPX: 4,
        heightPX: -1,
        lpaddingPX: 8,
        rpaddingPX: 8,
        tpaddingPX: 5,
        bpaddingPX: 5,
      },
      horizontal: {
        width: "-1",
        height: "0.25rem",
        lpadding: "0rem",
        rpadding: "0rem",
        tpadding: "0.5rem",
        bpadding: "0.5rem",
        widthPX: -1,
        heightPX: 4,
        lpaddingPX: 0,
        rpaddingPX: 0,
        tpaddingPX: 8,
        bpaddingPX: 8,
      },
    },
    image_dimension: {
      STATUS: {
        width: "1.10094rem",
        height: "1.46788rem",
        widthPX: 17.615,
        heightPX: 23.486,
        headerImage: StatusHeader_1280,
        loisImage: LOIsWorking_1280,
        inProgressImage: "",
        completedImage: Completed_1280,
      },
      UPLOAD_DIALOG_LOGO: {
        width: "6.75713rem",
        height: "0.93769rem",
        widthPX: 108.114,
        heightPX: 15.003,
        image: UploadLogo_1280,
      },
      USER: {
        width: "1.875rem",
        height: "1.875rem",
        widthPX: 30,
        heightPX: 30,
        image: UserIcon_1280,
      },
      DASHBOARD_LOGO: {
        width: "11.14938rem",
        height: "1.5625rem",
        widthPX: 178.39,
        heightPX: 25,
        image: DashboardLogo_1280,
      },
      COLUMN_PIN: {
        width: "0.43006rem",
        height: "0.7265rem",
        widthPX: 6.881,
        heightPX: 11.624,
        pinnedImage: ColumnPin_1280,
        unpinnedImage: ColumnUnPin_1280,
      },
      ROW_PIN: {
        width: "0.67888rem",
        height: "0.84313rem",
        widthPX: 10.862,
        heightPX: 13.49,
        pinnedImage: PinnedRow_1280,
        unpinnedImage: UnPinnedRow_1280,
      },
      SETTINGS: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 23.484,
        heightPX: 24.828,
        image: Settings_1280,
      },
      NOTES: {
        width: "0.6875rem",
        height: "0.4125rem",
        widthPX: 11,
        heightPX: 6.6,
        show: ShowNotes,
        hide: HideNotes_1280,
      },
      CHILDREN: {
        width: "0.625rem",
        height: "0.875rem",
        widthPX: 10,
        heightPX: 14,
        show: ShowChildren_1280,
        hide: HideChildren_1280,
      },
      TAB_ADD: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 10,
        heightPX: 10,
        image: NewTab,
      },
      TABS_VIEW: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 10,
        heightPX: 10,
        image: TabSelection,
      },
      TAB_ACTION: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 5,
        heightPX: 6,
        show: TabShow,
        hide: TabHide,
      },
      TAB_NAV_LEFT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 6.68,
        heightPX: 10,
        show: Tab_Active_Left_1280,
        hide: Tab_Inactive_Left_1280,
      },
      TAB_NAV_RIGHT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 6.68,
        heightPX: 10,
        show: Tab_Active_Right_1280,
        hide: Tab_Inactive_Right_1280,
      },
    },
    columns_dimension: {
      PIN: {
        width: "1.5625rem",
        widthPX: 25,
      },
      AUTHOR: {
        width: "4.6875rem",
        widthPX: 75,
      },
      STATUS: {
        width: "2.5rem",
        widthPX: 65,
      },
      MARKET: {
        width: "5.9375rem",
        widthPX: 95,
      },
      NOTES: {
        width: "9.6875rem",
        widthPX: 155,
      },
      NOTES_HIDDEN: {
        width: "1rem",
        widthPX: 32,
      },
      CHILDREN: {
        width: "2.5rem",
        widthPX: 45,
      },
      OTHERS: {
        width: "10.3125rem",
        widthPX: 165,
      },
      UPLOAD_STATUS: {
        width: "5.428rem",
        widthPX: 86.848,
      },
      UPLOAD_DOCUMENT_NAME: {
        width: "18.19969rem",
        widthPX: 291.965,
      },
      UPLOAD_UPLOADED: {
        width: "5.74725rem",
        widthPX: 91.956,
      },
    },
  },
  HD: {
    width: 1366,
    height: 768,
    rowHeightREM: "6.33644rem",
    rowHeight: 48.023,
    underHeaderHeight: 5.33,
    uploaderRowHeight: 28.088,
    uploaderHeaderRowHeight: 45,
    padding: 10.672,
    fontSize: 14.532,
    headerLineHeight: 17.545,
    headerGap: 12.532,
    textGap: 10.672,
    scroll_bar: {
      vertical: {
        width: "0.2668rem",
        height: "-1",
        lpadding: "0.5337rem",
        rpadding: "0.5337rem",
        tpadding: "0.3337rem",
        bpadding: "0.3337rem",
        widthPX: 4.269,
        heightPX: -1,
        lpaddingPX: 8.54,
        rpaddingPX: 8.54,
        tpaddingPX: 5.34,
        bpaddingPX: 5.34,
      },
      horizontal: {
        width: "-1",
        height: "0.2668rem",
        lpadding: "0rem",
        rpadding: "0rem",
        tpadding: "0.5337rem",
        bpadding: "0.5337rem",
        widthPX: -1,
        heightPX: 4.269,
        lpaddingPX: 0,
        rpaddingPX: 0,
        tpaddingPX: 8.54,
        bpaddingPX: 8.54,
      },
    },
    image_dimension: {
      STATUS: {
        width: "1.17488rem",
        height: "1.5665rem",
        widthPX: 18.798,
        heightPX: 25.064,
        headerImage: StatusHeader_1366,
        loisImage: LOIsWorking_1366,
        inProgressImage: "",
        completedImage: Completed_1366,
      },
      USER: {
        width: "2.001rem",
        height: "2.001rem",
        widthPX: 32.016,
        heightPX: 32.016,
        image: UserIcon_1366,
      },
      UPLOAD_DIALOG_LOGO: {
        width: "7.4375rem",
        height: "1.0506rem",
        widthPX: 119,
        heightPX: 16.81,
        image: UploadLogo_1366,
      },
      DASHBOARD_LOGO: {
        width: "11.8985rem",
        height: "1.6675rem",
        widthPX: 190.376,
        heightPX: 26.68,
        image: DashboardLogo_1366,
      },
      COLUMN_PIN: {
        width: "0.45894rem",
        height: "0.77531rem",
        widthPX: 7.343,
        heightPX: 12.405,
        pinnedImage: ColumnPin_1366,
        unpinnedImage: ColumnUnPin_1280,
      },
      ROW_PIN: {
        width: "0.7245rem",
        height: "0.89981rem",
        widthPX: 11.592,
        heightPX: 14.397,
        pinnedImage: PinnedRow_1366,
        unpinnedImage: UnPinnedRow_1366,
      },
      SETTINGS: {
        width: "1.56631rem",
        height: "1.656rem",
        widthPX: 25.06,
        heightPX: 26.5,
        image: Settings_1366,
      },
      NOTES: {
        width: "0.73369rem",
        height: "0.44019rem",
        widthPX: 11.739,
        heightPX: 7.043,
        show: ShowNotes,
        hide: HideNotes_1366,
      },
      CHILDREN: {
        width: "0.6669rem",
        height: "0.8669rem",
        widthPX: 10.67,
        heightPX: 13.87,
        show: ShowChildren_1366,
        hide: HideChildren_1366,
      },
      TAB_ADD: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 12,
        heightPX: 12,
        image: NewTab,
      },
      TABS_VIEW: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 12,
        heightPX: 12,
        image: TabSelection,
      },
      TAB_ACTION: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 5,
        heightPX: 6,
        show: TabShow,
        hide: TabHide,
      },
      TAB_NAV_LEFT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 7.13,
        heightPX: 10.67,
        show: Tab_Active_Left_1366,
        hide: Tab_Inactive_Left_1366,
      },
      TAB_NAV_RIGHT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 7.13,
        heightPX: 10.67,
        show: Tab_Active_Right_1366,
        hide: Tab_Inactive_Right_1366,
      },
    },
    columns_dimension: {
      PIN: {
        width: "1.6675rem",
        widthPX: 26.68,
      },
      AUTHOR: {
        width: "5.00244rem",
        widthPX: 80.039,
      },
      STATUS: {
        width: "2.668rem",
        widthPX: 75,
      },
      MARKET: {
        width: "6.33644rem",
        widthPX: 101.383,
      },
      NOTES: {
        width: "10.33838rem",
        widthPX: 165.414,
      },
      NOTES_HIDDEN: {
        width: "0.5rem",
        widthPX: 16,
      },
      CHILDREN: {
        width: "1.6675rem",
        widthPX: 26.68,
      },
      OTHERS: {
        width: "11.00538",
        widthPX: 176.086,
      },
      UPLOAD_STATUS: {
        width: "6rem",
        widthPX: 96,
      },
      UPLOAD_DOCUMENT_NAME: {
        width: "20.125rem",
        widthPX: 322,
      },
      UPLOAD_UPLOADED: {
        width: "6.375rem",
        widthPX: 102,
      },
    },
  },
  FULL_HD: {
    width: 1920,
    height: 1080,
    rowHeightREM: "4.21875rem",
    rowHeight: 67.5,
    underHeaderHeight: 7.5,
    uploaderHeaderRowHeight: 60,
    uploaderRowHeight: 37.5,
    padding: 15,
    fontSize: 20.615, //17.615,
    headerLineHeight: 24.661,
    headerGap: 17.615,
    textGap: 15,
    scroll_bar: {
      vertical: {
        width: "0.375rem",
        height: "-1",
        lpadding: "0.75rem",
        rpadding: "0.75rem",
        tpadding: "0.4688rem",
        bpadding: "0.4688rem",
        widthPX: 6,
        heightPX: -1,
        lpaddingPX: 12,
        rpaddingPX: 12,
        tpaddingPX: 7.5,
        bpaddingPX: 7.5,
      },
      horizontal: {
        width: "-1",
        height: "0.375rem",
        lpadding: "0rem",
        rpadding: "0rem",
        tpadding: "0.75rem",
        bpadding: "0.75rem",
        widthPX: -1,
        heightPX: 6,
        lpaddingPX: 0,
        rpaddingPX: 0,
        tpaddingPX: 12,
        bpaddingPX: 12,
      },
    },
    image_dimension: {
      STATUS: {
        width: "1.65138rem",
        height: "2.20181rem",
        widthPX: 26.422,
        heightPX: 35.229,
        headerImage: StatusHeader_1920,
        loisImage: LOIsWorking_1920,
        inProgressImage: "",
        completedImage: Completed_1920,
      },
      UPLOAD_DIALOG_LOGO: {
        width: "9.9369rem",
        height: "1.4063rem",
        widthPX: 158.99,
        heightPX: 22.5,
        image: UploadLogo_1920,
      },
      USER: {
        width: "2.8125rem",
        height: "2.8125rem",
        widthPX: 45,
        heightPX: 45,
        image: UserIcon_1920,
      },
      DASHBOARD_LOGO: {
        width: "16.7244rem",
        height: "1.5625rem",
        widthPX: 267.59,
        heightPX: 37.5,
        image: DashboardLogo_1920,
      },
      COLUMN_PIN: {
        width: "0.64506rem",
        height: "1.08975rem",
        widthPX: 10.321,
        heightPX: 17.436,
        pinnedImage: ColumnPin_1920,
        unpinnedImage: ColumnUnPin_1280,
      },
      ROW_PIN: {
        width: "1.01838rem",
        height: "1.26475rem",
        widthPX: 16.294,
        heightPX: 20.236,
        pinnedImage: PinnedRow_1920,
        unpinnedImage: UnPinnedRow_1920,
      },
      SETTINGS: {
        width: "2.2015rem",
        height: "2.32756rem",
        widthPX: 35.224,
        heightPX: 37.241,
        image: Settings_1920,
      },
      NOTES: {
        width: "1.0313rem",
        height: "0.6188rem",
        widthPX: 16.5,
        heightPX: 9.9,
        show: ShowNotes,
        hide: HideNotes_1920,
      },
      CHILDREN: {
        width: "0.9381rem",
        height: "1.2188rem",
        widthPX: 15,
        heightPX: 19.5,
        show: ShowChildren_1920,
        hide: HideChildren_1920,
      },
      TAB_ADD: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 15,
        heightPX: 15,
        image: NewTab,
      },
      TABS_VIEW: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 15,
        heightPX: 15,
        image: TabSelection,
      },
      TAB_ACTION: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 7,
        heightPX: 8,
        show: TabShow,
        hide: TabHide,
      },
      TAB_NAV_LEFT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 10.2,
        heightPX: 15,
        show: Tab_Active_Left_1920,
        hide: Tab_Inactive_Left_1920,
      },
      TAB_NAV_RIGHT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 10.2,
        heightPX: 15,
        show: Tab_Active_Right_1920,
        hide: Tab_Inactive_Right_1920,
      },
    },
    columns_dimension: {
      PIN: {
        width: "2.34375rem",
        widthPX: 37.5,
      },
      AUTHOR: {
        width: "7.03125rem",
        widthPX: 112.5,
      },
      STATUS: {
        width: "3.75rem",
        widthPX: 100,
      },
      MARKET: {
        width: "8.90625rem",
        widthPX: 142.5,
      },
      NOTES: {
        width: "14.53125rem",
        widthPX: 232.5,
      },
      NOTES_HIDDEN: {
        width: "0.5rem",
        widthPX: 16,
      },
      CHILDREN: {
        width: "2.34375rem",
        widthPX: 37.5,
      },
      OTHERS: {
        width: "18.5rem",
        widthPX: 247.5,
      },
      UPLOAD_STATUS: {
        width: "7.96875rem",
        widthPX: 127.5,
      },
      UPLOAD_DOCUMENT_NAME: {
        width: "26.71875rem",
        widthPX: 427.5,
      },
      UPLOAD_UPLOADED: {
        width: "8.4375rem",
        widthPX: 130,
      },
    },
  },
  QHD: {
    width: 2048,
    height: 1080,
    rowHeightREM: "4.21875rem",
    rowHeight: 67.5,
    underHeaderHeight: 7.5,
    uploaderHeaderRowHeight: 60,
    uploaderRowHeight: 37.5,
    padding: 15,
    fontSize: 20.615, //17.615,
    headerLineHeight: 24.661,
    headerGap: 17.615,
    textGap: 15,
    scroll_bar: {
      vertical: {
        width: "0.2668rem",
        height: "-1",
        lpadding: "0.5337rem",
        rpadding: "0.5337rem",
        tpadding: "0.3337rem",
        bpadding: "0.3337rem",
        widthPX: 4.269,
        heightPX: -1,
        lpaddingPX: 8.54,
        rpaddingPX: 8.54,
        tpaddingPX: 5.34,
        bpaddingPX: 5.34,
      },
      horizontal: {
        width: "-1",
        height: "0.375",
        lpadding: "0rem",
        rpadding: "0rem",
        tpadding: "0.75rem",
        bpadding: "0.75rem",
        widthPX: -1,
        heightPX: 6,
        lpaddingPX: 0,
        rpaddingPX: 0,
        tpaddingPX: 12,
        bpaddingPX: 12,
      },
    },
    image_dimension: {
      STATUS: {
        width: "1.65138rem",
        height: "2.20181rem",
        widthPX: 26.422,
        heightPX: 35.229,
        headerImage: StatusHeader_2048,
        loisImage: LOIsWorking_2048,
        inProgressImage: "",
        completedImage: Completed_2048,
      },
      USER: {
        width: "2.8125rem",
        height: "2.8125rem",
        widthPX: 45,
        heightPX: 45,
        image: UserIcon_2048,
      },
      UPLOAD_DIALOG_LOGO: {
        width: "9.9369rem",
        height: "1.4063rem",
        widthPX: 158.99,
        heightPX: 22.5,
        image: UploadLogo_2048,
      },
      DASHBOARD_LOGO: {
        width: "16.7244rem",
        height: "1.5625rem",
        widthPX: 267.59,
        heightPX: 37.5,
        image: DashboardLogo_2048,
      },
      COLUMN_PIN: {
        width: "0.64506rem",
        height: "1.08975rem",
        widthPX: 10.321,
        heightPX: 17.436,
        pinnedImage: ColumnPin_2048,
        unpinnedImage: ColumnUnPin_1280,
      },
      ROW_PIN: {
        width: "1.01838rem",
        height: "1.26475rem",
        widthPX: 16.294,
        heightPX: 20.236,
        pinnedImage: PinnedRow_2048,
        unpinnedImage: UnPinnedRow_2048,
      },
      SETTINGS: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 35.23,
        heightPX: 37.24,
        image: Settings_2048,
      },
      NOTES: {
        width: "1.0313rem",
        height: "0.6188rem",
        widthPX: 16.5,
        heightPX: 9.9,
        show: ShowNotes,
        hide: HideNotes_2048,
      },
      CHILDREN: {
        width: "0.9381rem",
        height: "1.2188rem",
        widthPX: 15.01,
        heightPX: 19.5,
        show: ShowChildren_2048,
        hide: HideChildren_2048,
      },
      TAB_ADD: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 15,
        heightPX: 15,
        image: NewTab,
      },
      TABS_VIEW: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 15,
        heightPX: 15,
        image: TabSelection,
      },
      TAB_ACTION: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 7,
        heightPX: 8,
        show: TabShow,
        hide: TabHide,
      },
      TAB_NAV_LEFT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 10.2,
        heightPX: 15,
        show: Tab_Active_Left_2048,
        hide: Tab_Inactive_Left_2048,
      },
      TAB_NAV_RIGHT: {
        width: "1.46775rem",
        height: "1.55175rem",
        widthPX: 10.2,
        heightPX: 15,
        show: Tab_Active_Right_2048,
        hide: Tab_Inactive_Right_2048,
      },
    },
    columns_dimension: {
      PIN: {
        width: "2.34375rem",
        widthPX: 37.5,
      },
      AUTHOR: {
        width: "7.03125rem",
        widthPX: 140,
      },
      STATUS: {
        width: "3.75rem",
        widthPX: 100,
      },
      MARKET: {
        width: "8.90625rem",
        widthPX: 160,
      },
      NOTES: {
        width: "14.53125rem",
        widthPX: 232.5,
      },
      NOTES_HIDDEN: {
        width: "0.5rem",
        widthPX: 16,
      },
      CHILDREN: {
        width: "2.34375rem",
        widthPX: 37.5,
      },
      OTHERS: {
        width: "18.5rem",
        widthPX: 296,
      },
      UPLOAD_STATUS: {
        width: "7.96875rem",
        widthPX: 127.5,
      },
      UPLOAD_DOCUMENT_NAME: {
        width: "26.71875rem",
        widthPX: 427.5,
      },
      UPLOAD_UPLOADED: {
        width: "8.4375rem",
        widthPX: 130,
      },
    },
  },
};

export const CUSTOM_COLUMN_TITLE = {
  PIN: "Pin",
  AUTHOR: "Author",
  STATUS: "Status",
  MARKET: "Market",
  LOI_DATE: "LOI Date",
  NOTES: "Deal Notes and Next Steps",
  NOTES_HIDDEN: "",
  CHILDREN: "Children",
};

export const CUSTOM_COLUMN_KEY = {
  PIN: "pin",
  AUTHOR: "author",
  STATUS: "status",
  MARKET: "market",
  LOI_DATE: "loi-date",
  NOTES: "notes",
  NOTES_HIDDEN: "notes-hidden",
  CHILDREN: "children",
};

export const CUSTOM_COLUMNS = {
  PIN: {
    id: -3,
    title: CUSTOM_COLUMN_TITLE.PIN,
    key: CUSTOM_COLUMN_KEY.PIN,
    order: -3,
    isMultiLine: false,
  },
  AUTHOR: {
    id: -2,
    title: CUSTOM_COLUMN_TITLE.AUTHOR,
    key: CUSTOM_COLUMN_KEY.AUTHOR,
    order: -2,
    isMultiLine: false,
  },
  STATUS: {
    id: -1,
    title: CUSTOM_COLUMN_TITLE.STATUS,
    key: CUSTOM_COLUMN_KEY.STATUS,
    order: -1,
    isMultiLine: false,
  },
  MARKET: {
    id: 0,
    title: CUSTOM_COLUMN_TITLE.MARKET,
    key: CUSTOM_COLUMN_KEY.MARKET,
    order: 0,
    isMultiLine: false,
  },
  NOTES: {
    id: 100,
    title: CUSTOM_COLUMN_TITLE.NOTES,
    key: CUSTOM_COLUMN_KEY.NOTES,
    order: 100,
    isMultiLine: true,
  },
  NOTES_HIDDEN: {
    id: 101,
    title: CUSTOM_COLUMN_TITLE.NOTES_HIDDEN,
    key: CUSTOM_COLUMN_KEY.NOTES_HIDDEN,
    order: 101,
    isMultiLine: true,
  },
  CHILDREN: {
    id: 102,
    title: CUSTOM_COLUMN_TITLE.CHILDREN,
    key: CUSTOM_COLUMN_KEY.CHILDREN,
    order: 102,
    isMultiLine: true,
  },
};

export const UPLOAD_HEADER = {
  STATUS: "Status",
  DOCUMENT_NAME: "Document Name",
  UPLOADED: "Uploaded",
};

export const DUMMY_ROWS = _.range(150).map((r) => {
  return {
    id: uniqueId(),
    name: "",
    status: LEASE_STATUS.DUMMY,
    notes: "",
    market: "",
    author: "",
    values: getBlankDocumentData(28),
  } as DocumentEntity;
});
