import {
  CellClickedEvent,
  ColDef,
  ColumnMovedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  IRowNode,
  RowDoubleClickedEvent,
  RowHeightParams,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useRef } from "react";
import { contextMenu } from "react-contexify";
import {
  AUTHOR_MENU_ID,
  CLIENT_DUMMY_ROW_IDS,
  CUSTOM_COLUMN_KEY,
  LEASE_STATUS,
  MARKET_MENU_ID,
  MENU_SUB_TYPE,
  STATUS_MENU_ID,
} from "../../../common/constants";
import { CustomDashboardParams } from "../../../common/types/dashboard/DashboardUITypes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { FieldEntity } from "../../../common/types/EntityTypes";
import { DocumentEntity } from "../../../common/types/EntityTypes";
import {
  fetchDocumentHTML,
  selectCurrentTab,
  selectSelectedDocumentExpandedId,
  selectSelectedDocumentId,
  setSelectedDocument,
  updateRowReadStatus,
} from "../../../redux/reducers/dashboardSlice";
import {
  markDocumentRead,
  selectChildrenForParent,
  selectRows,
} from "../../../redux/reducers/documentsDataSlice";
import { selectFields } from "../../../redux/reducers/metadataSlice";
import {
  selectMenu,
  setAbstraction,
  setMenu,
} from "../../../redux/reducers/popupSlice";
import {
  selectCellHeight,
  selectFontPer,
  selectResolutionMetadata,
  selectUnderHeaderHeight,
} from "../../../redux/reducers/uiSlice";
import "./Table.scss";
import TableCell from "./table-cell/TableCell";
import TableHeader from "./table-header/TableHeader";
import { selectLeaseStatus } from "../../../redux/reducers/leaseStatusSlice";
import { updateDocumentReadStatus } from "../../../db/documentDBAction";

// Images import

function Table(props: CustomDashboardParams) {
  const gridRef = useRef<AgGridReact<DocumentEntity>>(null);

  //Font change Per
  const fontChangePer = useAppSelector(selectFontPer);
  const resMetadata = useAppSelector(selectResolutionMetadata);
  const gridRowHeight = useAppSelector(selectCellHeight);
  const underHeaderHeight = useAppSelector(selectUnderHeaderHeight);
  // Selected Tab
  const selectedTab = useAppSelector(selectCurrentTab);

  // Headers Selection
  const headers = useAppSelector(selectFields);
  // Rows App Selector
  const rows = useAppSelector(selectRows);
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  // Children For Current Selected Row
  const children = useAppSelector(selectChildrenForParent);
  const expandedParent = useAppSelector(selectSelectedDocumentExpandedId);

  const leaseStatusFilters = useAppSelector(selectLeaseStatus);

  // Menu
  const selectedMenu = useAppSelector(selectMenu);

  const dispatch = useAppDispatch();

  const getRowHeight = useCallback(
    (params: RowHeightParams) => {
      if (params.data.locked) {
        return resMetadata.headerLineHeight * 10 + resMetadata.padding * 2;
      }
      return gridRowHeight;
    },
    [fontChangePer]
  );

  const getColumnWidth = (headerKey: String) => {
    switch (headerKey) {
      case CUSTOM_COLUMN_KEY.PIN:
        return resMetadata.columns_dimension.PIN.widthPX;
      case CUSTOM_COLUMN_KEY.AUTHOR:
        return resMetadata.columns_dimension.AUTHOR.widthPX;
      case CUSTOM_COLUMN_KEY.STATUS:
        return resMetadata.columns_dimension.STATUS.widthPX;
      case CUSTOM_COLUMN_KEY.MARKET:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.NOTES:
        return resMetadata.columns_dimension.NOTES.widthPX;
      case CUSTOM_COLUMN_KEY.NOTES_HIDDEN:
        return resMetadata.columns_dimension.NOTES_HIDDEN.widthPX;
      case CUSTOM_COLUMN_KEY.CHILDREN:
        return resMetadata.columns_dimension.CHILDREN.widthPX;
      default:
        return resMetadata.columns_dimension.OTHERS.widthPX;
    }
  };

  const transformedHeadersDef = useMemo(() => {
    if (headers.length > 0) return transformHeaders(headers);
  }, [headers, selectedDocumentId]);

  const transformedRowsData = useMemo(() => {
    const clientsWithData = Object.keys(rows);

    const dummyRowsFromIndex = localStorage.getItem(CLIENT_DUMMY_ROW_IDS);

    // if data is present and selected tab has no data available
    let data: DocumentEntity[] = [];
    if (
      clientsWithData.length == 0 ||
      clientsWithData.indexOf(selectedTab) < 0
    ) {
      data = [];
    } else {
      data = rows[selectedTab].filter((row) => {
        return row.parent == "" || expandedParent.includes(row.parent);
        // if (row.parent == "") return true;
        // else {
        //   if (expandedParent.includes(row.parent)) return false;
        // }
        // return false;
      });
    }

    if (dummyRowsFromIndex != null) {
      const dummyClientData = JSON.parse(dummyRowsFromIndex)[selectedTab];
      if (dummyClientData) {
        const dummyRows = dummyClientData.slice(0, 50 - data.length);
        data = [...data, ...dummyRows];
      }
    }
    return prepareRows(data);
  }, [rows, selectedTab, expandedParent]);

  const components = useMemo(
    () => ({
      agColumnHeader: TableHeader,
    }),
    []
  );

  function transformHeaders(headers: FieldEntity[]): ColDef[] {
    // Prepare the Column Defination
    function prepareColDef(header: FieldEntity) {
      // Default Column Def
      let colDef: ColDef = {
        colId: header.name,
        headerName: "",
        pinned: isColumnPinnedAndLocked(header.pinned, header.order),
        headerComponentParams: {
          customData: header,
        },
        headerClass: (params) => {
          const classNames = [];
          // if (header.order < 0 || header.order == 100 || header.order == 101) {
          if (header.order == 102) {
            classNames.push("setting-column-color");
          } else if (
            isColumnPinnedAndLocked(header.pinned, header.order) != null
          ) {
            classNames.push("pinned-column-header");
          } else {
            classNames.push("normal-column-color");
          }
          return classNames;
        },
        wrapHeaderText: true,
        wrapText: true,

        cellClass: (params) => {
          const classNames: string[] = [];
          if (params.data.id == selectedDocumentId) {
            classNames.push("selected-row-color");
          } else {
            if (
              header.order < 0 ||
              header.order == 100 ||
              header.order == 101
            ) {
              classNames.push("pinned-column-cell-color");
            } else if (header.order == 0) {
              classNames.push("market-column");
            }
          }
          return classNames;
        },
        suppressMovable: header.order < 0 && header.order >= 100 ? true : false,
        resizable: header.order >= 0 && header.order < 100 ? true : false,
        width: getColumnWidth(header.code),
        lockPosition: header.pinned
          ? header.order < 0
            ? "left"
            : header.order > 100
            ? "right"
            : undefined
          : undefined,
        initialHide:
          header.code == CUSTOM_COLUMN_KEY.NOTES_HIDDEN ? true : false,
        cellRenderer: TableCell,
        cellRendererParams: { customData: header },
      };

      // if (header.order < 0) {
      //   colDef["lockPosition"] = "left";
      // } else if (header.order >= 100) {
      //   colDef["lockPosition"] = "right";
      // }

      // colDef["cellRenderer"] = TableCell;
      // colDef["cellRendererParams"] = { customData: header };

      return colDef;
    }

    function isColumnPinnedAndLocked(customPinned: boolean, order: number) {
      if (order < 0 || customPinned) {
        return "left";
      } else if (order >= 100) {
        return "right";
      }
      return null;
    }

    return [...headers]
      .sort((h1, h2) => h1.order - h2.order)
      .map((header) => prepareColDef(header));
  }

  // function prepareRows(rows: DType[], filterBy: string) {
  function prepareRows(rows: DocumentEntity[]) {
    let fieldValues: DocumentEntity[] = rows;
    return fieldValues;
  }

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<DocumentEntity>) => params.data.id;
  }, []);

  const isRowSelectable = (rowData: IRowNode<DocumentEntity>) => {
    return rowData.data?.status != LEASE_STATUS.DUMMY;
  };

  // const displayMenu = (e: CellClickedEvent, data: string, menuType: string) => {
  //   if (e.event != null && e.event != undefined) {
  //     if (menuType == MENU_SUB_TYPE.STATUS) {
  //       dispatch(setMenu(STATUS_MENU_ID));
  //     } else if (menuType == MENU_SUB_TYPE.AUTHOR) {
  //       dispatch(setMenu(AUTHOR_MENU_ID));
  //     } else if (menuType == MENU_SUB_TYPE.MARKET) {
  //       dispatch(setMenu(MARKET_MENU_ID));
  //     }
  //     contextMenu.show({
  //       id: selectedMenu,
  //       event: new MouseEvent("click"),
  //       props: { data },
  //       position: {
  //         x: 0,
  //         y: 0,
  //       },
  //     });
  //   }
  // };

  const handleCellClick = (event: CellClickedEvent) => {
    const restrictiveFieldCodes = [
      "Author",
      "LOI Status",
      "Market",
      "Children",
      "",
    ];

    const headersName = headers
      .filter((header) => restrictiveFieldCodes.indexOf(header.name) >= 0)
      .map((header) => header.name);

    dispatch(setSelectedDocument(event.data));
    if (event.colDef.colId && headersName.indexOf(event.colDef.colId) >= 0) {
      if (event.colDef.colId === "Children") {
        console.log(children);
      } else if (event.colDef.colId === "LOI Status") {
        // displayMenu(event, event.node.data, MENU_SUB_TYPE.AUTHOR);
      }
    } else {
      gridRef.current?.api.forEachNode(function (rowNode) {
        if (rowNode.data?.id && rowNode.isSelected()) {
          if (!rowNode.data.read) {
            updateDocumentReadStatus(rowNode.data?.id, true, false)?.then(
              () => {
                dispatch(
                  markDocumentRead({
                    clientId: selectedTab,
                    documentId: rowNode.data?.id,
                  })
                );
                dispatch(updateRowReadStatus(true)).then(() =>
                  updateDocumentReadStatus(rowNode.data?.id, true, false)
                );
              }
            );
          }
          rowNode.setRowHeight(
            resMetadata.headerLineHeight * 10 + resMetadata.padding * 2
          );
        } else {
          if (!rowNode.data?.locked) rowNode.setRowHeight(gridRowHeight);
        }
      });
      event.api.onRowHeightChanged();
    }
  };

  // handle the row selection
  // const handleRowSelection = (event: RowSelectedEvent) => {
  //   gridRef.current?.api.forEachNode(function (rowNode) {
  //     if (rowNode.data?.id && rowNode.isSelected()) {
  //       rowNode.setRowHeight(
  //         resMetadata.headerLineHeight * 10 + resMetadata.padding * 2
  //       );
  //     } else {
  //       if (!rowNode.data?.locked) rowNode.setRowHeight(gridRowHeight);
  //     }
  //   });
  //   event.api.onRowHeightChanged();
  //   dispatch(setSelectedDocument(event.data));
  // };

  // Show abstraction on the double click
  const handleRowDoubleClick = useCallback((event: RowDoubleClickedEvent) => {
    dispatch(setAbstraction());
    dispatch(fetchDocumentHTML());
  }, []);

  const handleColumnDrag = useCallback((event: ColumnMovedEvent) => {
    if (event.finished) console.log(event);
  }, []);

  const isExternalFilterPresent = useCallback(() => {
    return true;
  }, []);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<DocumentEntity>) => {
      const appliedFilters = leaseStatusFilters
        .filter((filter) => filter.selected)
        .map((filter) => filter.name);
      if (node.data) {
        const lStatus = node.data.status;
        if (appliedFilters.includes(lStatus)) {
          return true;
        }
      }
      return false;
    },
    [leaseStatusFilters]
  );

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: "calc(100% - 2.7rem)" }}
      >
        <AgGridReact<DocumentEntity>
          onColumnMoved={handleColumnDrag}
          ref={gridRef}
          components={components}
          columnDefs={transformedHeadersDef}
          rowData={transformedRowsData}
          getRowHeight={getRowHeight}
          headerHeight={gridRowHeight + underHeaderHeight}
          rowSelection="single"
          getRowId={getRowId}
          rowDragManaged={true}
          animateRows={true}
          isRowSelectable={isRowSelectable}
          suppressCellFocus={true}
          onRowDoubleClicked={handleRowDoubleClick}
          onCellClicked={handleCellClick}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          // onRowSelected={handleRowSelection}
          // onCellClicked={onCellClicked}
          // suppressRowDeselection={false}
          // suppressRowClickSelection={suppressCellSelection}
        ></AgGridReact>
      </div>
    </>
  );
}
export default Table;
