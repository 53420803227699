import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { API_VARIABLE_USER_ID, VALUES_CLIENTS } from "../../api/api-constants";
import { AXIOS } from "../../api/axios";
import { InitializationError } from "../../common/errors";
import { DocumentEntity } from "../../common/types/EntityTypes";
import {
  mapDocumentEntityToOfflineEntity,
  mapOfflineDocumentEntityToDocumentEntity,
} from "../../common/types/Mapper";
import { DocumentsState } from "../../common/types/SliceTypes";
import {
  fetchOfflineDocumentsByClients,
  upsertDocuments,
} from "../../db/documentDBAction";
import { RootState } from "../store";
import { selectCurrentTab, selectSelectedDocumentId } from "./dashboardSlice";

const initialState = {} as DocumentsState;

export const documentsDataSlice = createSlice({
  name: "documentsData",
  initialState: initialState,
  reducers: {
    setDocuments: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    markDocumentRead: (state, action) => {
      const clientId = action.payload["clientId"];
      const documentId = action.payload["documentId"];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.read = !cDocument.read;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    updateStatusForDocument: (state, action) => {
      const clientId = action.payload["clientId"];
      const documentId = action.payload["documentId"];
      const status = action.payload["status"];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.status = status;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    updateMarketForDocument: (state, action) => {
      const clientId = action.payload["clientId"];
      const documentId = action.payload["documentId"];
      const marketExternalId = action.payload["marketExternalId"];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.market = marketExternalId;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    updateAuthorForDocument: (state, action) => {
      const clientId = action.payload["clientId"];
      const documentId = action.payload["documentId"];
      const authorId = action.payload["authorId"];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.author = authorId;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    mapRowPin: (state, action) => {
      const clientId = action.payload["clientId"];
      const documentId = action.payload["documentId"];
      const pinStatus = action.payload["status"];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.locked = !pinStatus;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
    toggleRowPin: (state, action) => {
      const clientId = action.payload["clientId"];
      const documentId = action.payload["documentId"];

      if (clientId && clientId in state) {
        return {
          ...state,
          [clientId]: state[clientId].map((document) => {
            if (document.id == documentId) {
              const cDocument = { ...document };
              cDocument.locked = !cDocument.locked;
              return cDocument;
            }
            return document;
          }),
        };
      }
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(
        fetchUserClientsDocuments.fulfilled,
        fetchUserClientsDocuments.pending
      ),
      (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      }
    );
  },
});

export const {
  setDocuments,
  toggleRowPin,
  updateAuthorForDocument,
  updateMarketForDocument,
  updateStatusForDocument,
  markDocumentRead,
  mapRowPin,
} = documentsDataSlice.actions;

export const selectRows = (state: RootState) => state.documentsData;

export const selectRowsForClients = createSelector(
  [selectRows, selectCurrentTab],
  (rows, clientId) => {
    if (Object.keys(rows).indexOf(clientId) < 0) {
      return [];
    } else {
      return rows[clientId];
    }
  }
);

export const findClientIdForDocumentId = createSelector(
  [selectRows],
  (rows) =>
    (documentId: string): string | undefined => {
      const clientIds = Object.keys(rows);
      for (const clientId of clientIds) {
        const documents = rows[clientId];
        const foundDoc = documents.find((doc) => doc.id === documentId);

        if (foundDoc) {
          return clientId;
        }
      }
      return undefined;
    }
);

export const selectChildrenForParent = createSelector(
  [selectRowsForClients, selectSelectedDocumentId],
  (rowsForClientId, documentId) => {
    return rowsForClientId.filter((row) => row.parent == documentId);
  }
);

export const fetchUserClientsDocuments = createAsyncThunk(
  "/user/clients/documents",
  async (userData: { clientIds: string[]; userId: string }) => {
    if (userData) {
      if (userData.clientIds && userData.clientIds.length > 0) {
        //Fetch the Offline Documents
        let clientsDocsOffline = await fetchOfflineDocumentsByClients(
          userData.clientIds
        );

        // Transform to the respective format and group them
        let clientDocuments = _.groupBy(
          clientsDocsOffline.map((f) =>
            mapOfflineDocumentEntityToDocumentEntity(f)
          ),
          "client"
        );

        const url = VALUES_CLIENTS.replace(
          API_VARIABLE_USER_ID,
          userData.userId
        );

        AXIOS.post(url, userData.clientIds)
          .then(async (response) => {
            if (response.status == 200) {
              const data = response.data.data;

              const docs = Object.values(data)
                .map((data) => data as DocumentEntity)
                .flat()
                .map((doc) => mapDocumentEntityToOfflineEntity(doc, true));

              await upsertDocuments(docs);

              clientDocuments = { ...data };
            }
          })
          .catch(() => {
            if (clientDocuments == undefined) {
              throw new InitializationError("Client Documents not available.");
            }
          });
        return clientDocuments;
      }
    }
  }
);

export default documentsDataSlice.reducer;
