/**
 * Field DB Action
 */

import {
  FieldOfflineEntity,
  FieldUpdateOfflineEntity,
} from "../common/types/DB";
import { db } from "./db";

export const fetchOfflineFields = () => {
  return db.fieldValues.toArray();
};

export const upsertFields = (values: FieldOfflineEntity[]) => {
  if (values) return db.fieldValues.bulkPut(values);
};

/**
 * Field Update Values
 * @param value
 * @returns
 */

export const upsertFieldValues = (value: FieldUpdateOfflineEntity) => {
  return db.fieldUpdateValues.put(value);
};
