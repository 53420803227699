import classNames from "classnames";
import { Item } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { LEASE_STATUS } from "../../../common/constants";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectCurrentTab,
  selectSelectedDocumentId,
} from "../../../redux/reducers/dashboardSlice";
import { updateDocumentLeaseStatus } from "../../../redux/reducers/documentsStatusSlice";
import { selectLeaseStatus } from "../../../redux/reducers/leaseStatusSlice";
import "./LeaseStatus.scss";
import {
  updateLeaseStatusForDocumentID,
  updatePersistanceForDocumentID,
} from "../../../db/documentDBAction";
import { updateStatusForDocument } from "../../../redux/reducers/documentsDataSlice";

function LeaseStatus() {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectLeaseStatus);
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  const selectedClientId = useAppSelector(selectCurrentTab);

  const getCSSForLeaseStatus = (name: string) => {
    if (name == LEASE_STATUS.DEAD_DEAL) {
      return "dead-deal";
    } else if (name == LEASE_STATUS.IN_LEASE) {
      return "in-lease";
    } else {
      return "active-lease";
    }
  };

  const handleStatusUpdate = (name: string) => {
    updateLeaseStatusForDocumentID(selectedDocumentId, name)?.then(() => {
      dispatch(
        updateStatusForDocument({
          clientId: selectedClientId,
          documentId: selectedDocumentId,
          status: name,
        })
      );
      dispatch(updateDocumentLeaseStatus(name)).then(() => {
        updatePersistanceForDocumentID(selectedDocumentId, true);
      });
    });
  };
  return (
    <section>
      {filters
        .filter(
          (filter) =>
            filter.name != LEASE_STATUS.NONE &&
            filter.name != LEASE_STATUS.DUMMY
        )
        .map((filter, index) => {
          return (
            <Item
              key={filter.name}
              id={filter.name}
              onClick={(e) => handleStatusUpdate(filter.name)}
            >
              <span
                className={classNames(
                  "menu-status-text",
                  getCSSForLeaseStatus(filter.name)
                )}
              >
                {filter.text}
              </span>
            </Item>
          );
        })}
    </section>
  );
}
export default LeaseStatus;
