import "./Author.scss";
import "react-contexify/dist/ReactContexify.css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAuthors } from "../../../redux/reducers/metadataSlice";
import { Item } from "react-contexify";
import {
  selectCurrentTab,
  selectSelectedDocumentId,
  updateDocumentAuthor,
} from "../../../redux/reducers/dashboardSlice";
import classNames from "classnames";
import { AUTHOR } from "../../../common/constants";
import {
  updateAuthorForDocumentID,
  updatePersistanceForDocumentID,
} from "../../../db/documentDBAction";
import { updateAuthorForDocument } from "../../../redux/reducers/documentsDataSlice";

function Author() {
  const dispatch = useAppDispatch();
  const authors = useAppSelector(selectAuthors);
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  const selectedClientId = useAppSelector(selectCurrentTab);

  const handleAuthorUpdate = (authorExternalId: string) => {
    updateAuthorForDocumentID(selectedDocumentId, authorExternalId)?.then(
      () => {
        dispatch(
          updateAuthorForDocument({
            clientId: selectedClientId,
            documentId: selectedDocumentId,
            authorId: authorExternalId,
          })
        );
        dispatch(
          updateDocumentAuthor({
            documentId: selectedDocumentId,
            authorExternalId: authorExternalId,
          })
        ).then(() => {
          updatePersistanceForDocumentID(selectedDocumentId, true);
        });
      }
    );
  };

  const getCSSForAuthor = (type: string) => {
    if (type.toLowerCase() == AUTHOR.LANDLORD.toLowerCase()) {
      return "author-landlord-color";
    } else if (type.toLowerCase() == AUTHOR.TENANT.toLowerCase()) {
      return "author-tenant-color";
    } else {
      return "author-no-color";
    }
  };
  return (
    <section>
      {authors.map((author, index) => {
        return (
          <Item
            key={author.externalId}
            id={author.externalId}
            onClick={(event) => handleAuthorUpdate(author.externalId)}
          >
            <span
              className={classNames(
                "menu-status-text",
                getCSSForAuthor(author.name)
              )}
            >
              {author.name}
            </span>
          </Item>
        );
      })}
    </section>
  );
}
export default Author;
