import classNames from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SETTINGS_TAB_NAME } from "../../../common/constants";
import { ImageMetadata } from "../../../common/types/dashboard/DashboardUITypes";
import { useAppSelector } from "../../../redux/hooks";
import { selectUploadDialogLogoMetadata } from "../../../redux/reducers/uiSlice";
import "./Settings.scss";
import MarketSettings from "./market-settings/Market";

function Settings() {
  const dispatch = useDispatch();
  const logo: ImageMetadata = useAppSelector(selectUploadDialogLogoMetadata);
  const [selectedSettings, setSelectedSettings] = useState(
    SETTINGS_TAB_NAME.GENERAL
  );

  return (
    <div className="settings-holder">
      <div className="flex flex-align-end">
        <img src={logo.image}></img>
      </div>
      <div className="flex settings-tab-content-container">
        <div className="flex settings-tab-container">
          {Object.values(SETTINGS_TAB_NAME).map((value) => {
            return (
              <div
                className={classNames(
                  "setting-tab",
                  selectedSettings == value
                    ? "setting-tab-text-selected"
                    : "setting-tab-text-not-selected"
                )}
                style={{ cursor: "pointer" }}
                onClick={() => setSelectedSettings(value)}
              >
                <span className="setting-tab-text">{value}</span>
              </div>
            );
          })}
        </div>
        <div className="flex flex-basis settings-content-container">
          {selectedSettings == SETTINGS_TAB_NAME.MARKET && (
            <MarketSettings></MarketSettings>
          )}
        </div>
      </div>
      <div className="flex flex-align-end settings-button-container">
        <button className="settings-button">
          <span className="settings-button-text">Close</span>
        </button>
        <button className="settings-button button-orange">
          <span className="settings-button-text">OK</span>
        </button>
      </div>
    </div>
  );
}

export default Settings;
