import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./reducers/dashboardSlice";
import documentReducer from "./reducers/documentSlice";
import documentsReducer from "./reducers/documentsSlice";
import documentStatusReducer from "./reducers/documentsStatusSlice";
import leaseStatusReducer from "./reducers/leaseStatusSlice";
import popupReducer from "./reducers/popupSlice";
import uiReducer from "./reducers/uiSlice";
import userReducer from "./reducers/userSlice";
import metadataReducer from "./reducers/metadataSlice";
import documentsDataReducer from "./reducers/documentsDataSlice";
import appReducer from "./reducers/appSlice";
import { listenerMiddleware } from "./listeners";

export const store = configureStore({
  reducer: {
    documentsStatus: documentStatusReducer,
    user: userReducer,
    document: documentReducer,
    documents: documentsReducer,
    popup: popupReducer,
    leaseStatus: leaseStatusReducer,
    dashboard: dashboardReducer,
    ui: uiReducer,
    metadata: metadataReducer,
    documentsData: documentsDataReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["app/setEmitter"],
        // Ignore these paths in the state
        ignoredPaths: ["app.emitter"],
      },
    }).prepend(listenerMiddleware.middleware),
  // devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
