import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  API_VARIABLE_USER_ID,
  FIELDS,
  FIELD_UPDATE_ATTRIBUTES,
  GET_AUTHORS,
  GET_CLIENTS,
  GET_MARKETS,
} from "../../api/api-constants";
import { AXIOS } from "../../api/axios";
import { InitializationError } from "../../common/errors";
import {
  AuthorEntity,
  ClientEntity,
  ClientServerDTO,
  FieldEntity,
  FieldUpdatePayload,
  MarketEntity,
} from "../../common/types/EntityTypes";
import {
  mapAuthorEntityToOfflineAuthorEntity,
  mapClientServerDTOToOfflineEntity,
  mapFieldEntityToOfflineFieldEntity,
  mapMarketEntityToOfflineMarketEntity,
  mapOfflineAuthorEntityToAuthorEntity,
  mapOfflineClientEntityToClientEntity,
  mapOfflineFieldEntityToFieldEntity,
  mapOfflineMarketEntityToMarketEntity,
} from "../../common/types/Mapper";
import { MetadataState } from "../../common/types/SliceTypes";
import { fetchOfflineAuthors, upsertAuthors } from "../../db/authorDBAction";
import { fetchOfflineClients, upsertClients } from "../../db/clientDBAction";
import { fetchOfflineFields, upsertFields } from "../../db/fieldDBAction";
import {
  fetchMarkets as fetchOfflineMarkets,
  upsertMarkets,
} from "../../db/marketDBAction";
import { RootState } from "../store";

const initialState = {} as MetadataState;

export const metadataSlice = createSlice({
  name: "metadata",
  initialState: initialState,
  reducers: {
    addNewClient: (state, action) => {
      state.clients.push(action.payload);
    },
    updateClientOrder: (state, action) => {
      return {
        ...state,
        clients: action.payload,
      };
    },
    setAuthors: (state, action) => {
      return {
        ...state,
        authors: action.payload,
      };
    },
    setMarkets: (state, action) => {
      return {
        ...state,
        markets: action.payload,
      };
    },
    setClients: (state, action) => {
      return {
        ...state,
        clients: action.payload,
      };
    },
    setClientName: (state, action) => {
      return {
        ...state,
        clients: state.clients.map((c) => {
          if (c.id == action.payload["id"]) {
            const copyClient = { ...c };
            copyClient.name = action.payload["name"];
            return copyClient;
          }
          return c;
        }),
      };
    },
    setFields: (state, action) => {
      return {
        ...state,
        fields: action.payload,
      };
    },
    toggleFieldPin: (state, action) => {
      const currentFields = [...state.fields];
      const updatedFields = currentFields.map((field) => {
        if (field.id == action.payload) {
          const f = { ...field };
          f.pinned = !f.pinned;
          return f;
        }
        return field;
      });
      return {
        ...state,
        fields: updatedFields,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateFieldAttribute.fulfilled, (state, action) => {
        const updateField = action.meta.arg;
        let currentFields = [...state.fields];
        currentFields.forEach((field) => {
          if (field.code == updateField.code) {
            field.pinned = updateField.isPinned;
            field.visible = updateField.isVisible;
            field.name = updateField.name;
            field.order = updateField.order;
          }
        });
        return {
          ...state,
          fields: currentFields,
        };
      })
      .addMatcher(
        isAnyOf(fetchDefaultAuthors.pending, fetchDefaultAuthors.fulfilled),
        (state, action) => {
          // if the data is fetched from the backend update the local db
          if (action.payload != null || action.payload != undefined) {
            return {
              ...state,
              authors: action.payload,
            };
          }
        }
      )
      .addMatcher(
        isAnyOf(fetchUserMarkets.fulfilled, fetchUserMarkets.pending),
        (state, action) => {
          // if the data is fetched from the backend update the local db
          if (action.payload != null || action.payload != undefined) {
            return {
              ...state,
              markets: action.payload,
            };
          }
        }
      )
      .addMatcher(
        isAnyOf(fetchUserFields.pending, fetchUserFields.fulfilled),
        (state, action) => {
          if (action.payload != null || action.payload != undefined) {
            return {
              ...state,
              fields: action.payload,
            };
          }
        }
      )
      .addMatcher(
        isAnyOf(fetchUserClients.pending, fetchUserClients.fulfilled),
        (state, action) => {
          if (action.payload != null || action.payload != undefined) {
            return {
              ...state,
              clients: action.payload,
            };
          }
        }
      );
  },
});

export const {
  addNewClient,
  updateClientOrder,
  setAuthors,
  setMarkets,
  setClients,
  setFields,
  toggleFieldPin,
  setClientName,
} = metadataSlice.actions;

export const selectAuthors = (state: RootState) => state.metadata.authors;
export const selectMarkets = (state: RootState) => state.metadata.markets;
export const selectClients = (state: RootState) =>
  state.metadata.clients != undefined ? state.metadata.clients : [];
export const selectSelectedClient = (state: RootState) =>
  state.metadata.clients.find((c) => c.id == state.dashboard.selectedTab);
export const selectFields = (state: RootState) =>
  state.metadata.fields != undefined ? state.metadata.fields : [];
export const selectFieldLength = (state: RootState) =>
  state.metadata.fields != undefined ? state.metadata.fields.length : 28;
export const selectFieldByCode = (state: RootState, fieldCode: string) => {
  if (state.metadata.fields != undefined) {
    state.metadata.fields.find((field) => field.code == fieldCode);
  }
};

export default metadataSlice.reducer;

/** API Calls for the data */
export const fetchDefaultAuthors = createAsyncThunk("authors", async () => {
  let authorsOffline = await fetchOfflineAuthors();
  let authors = authorsOffline.map((a) =>
    mapOfflineAuthorEntityToAuthorEntity(a)
  );
  AXIOS.get(GET_AUTHORS)
    .then(async (response) => {
      if (response.status == 200) {
        authors = response.data.data;
        await upsertAuthors(
          authors.map((author: AuthorEntity) =>
            mapAuthorEntityToOfflineAuthorEntity(author, true)
          )
        );
      }
    })
    .catch(() => {
      if (authors == undefined || authors.length == 0) {
        throw new InitializationError("Authors not available.");
      }
    });
  return authors;
});

export const fetchUserMarkets = createAsyncThunk(
  "/user/markets",
  async (userId: string, { rejectWithValue }) => {
    if (userId) {
      let marketsOffline = await fetchOfflineMarkets();
      let markets = marketsOffline.map((m) =>
        mapOfflineMarketEntityToMarketEntity(m)
      );
      AXIOS.get(GET_MARKETS + "/" + userId)
        .then(async (response) => {
          if (response.status == 200) {
            markets = response.data.data;
            await upsertMarkets(
              markets.map((market: MarketEntity) =>
                mapMarketEntityToOfflineMarketEntity(market, true)
              )
            );
          }
        })
        .catch(() => {
          if (markets == undefined || markets.length == 0) {
            throw new InitializationError("Markets not available.");
          }
        });
      return markets;
    }
  }
);

export const fetchUserClients = createAsyncThunk(
  "/user/clients",
  async (id: string) => {
    let clientsOffline = await fetchOfflineClients();
    let clients = clientsOffline.map((c) =>
      mapOfflineClientEntityToClientEntity(c)
    );
    AXIOS.get(GET_CLIENTS.replace(API_VARIABLE_USER_ID, id))
      .then(async (response) => {
        if (response.status == 200) {
          const serverDTO: ClientServerDTO[] = response.data.data;
          clientsOffline = serverDTO.map((client) =>
            mapClientServerDTOToOfflineEntity(client, true)
          );
          upsertClients(clientsOffline);

          const mappedClients: ClientEntity[] = serverDTO.map((client) => {
            return {
              id: client.externalId,
              isCustom: client.custom,
              name: client.name,
              viewOrder: client.viewOrder,
              isDefault: client.default,
              alias: [],
            };
          });
          clients = mappedClients;
        }
      })
      .catch(() => {
        if (clients == undefined || clients.length == 0) {
          throw new InitializationError("User Clients/Tab not available.");
        }
      });
    return clients;
  }
);

export const fetchUserFields = createAsyncThunk(
  "/fields",
  async (id: string) => {
    if (id) {
      let fieldsOffline = await fetchOfflineFields();
      let fields = fieldsOffline.map((f) =>
        mapOfflineFieldEntityToFieldEntity(f)
      );
      AXIOS.get(FIELDS + id)
        .then(async (response) => {
          if (response.status == 200) {
            fields = response.data.data;
            await upsertFields(
              fields.map((field: FieldEntity) =>
                mapFieldEntityToOfflineFieldEntity(field, true)
              )
            );
          }
        })
        .catch(() => {
          if (fields == undefined || fields.length == 0) {
            throw new InitializationError("Fields not available.");
          }
        });
      return fields;
    }
  }
);

export const updateFieldAttribute = createAsyncThunk(
  "/field/attribute",
  async (requestPayload: FieldUpdatePayload, { getState }) => {
    try {
      const state = getState() as RootState;
      let url = FIELD_UPDATE_ATTRIBUTES.replace(
        API_VARIABLE_USER_ID,
        state.user.id
      );
      const response = await AXIOS.post(url, requestPayload);
      if (response) {
        return response.data;
      }
    } catch (err) {
      console.error(err);
    }
  }
);
