import { useWindowSize } from "@react-hook/window-size/throttled";
import { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  CLIENT_NAME_COUNTER_STORAGE_KEY,
  DELETE_ACTION_DOC_IDS,
} from "../common/constants";
import {
  getDimensionForResolution,
  setupLocalStorageWithDummyRows,
} from "../common/util";
import { fetchOfflineClients } from "../db/clientDBAction";
import { fetchOfflineFields } from "../db/fieldDBAction";
import { fetchConnectionId, upsertUserDetails } from "../db/userDBAction";
import { useAppDispatch, useAppSelector, useInterval } from "./hooks";
import { selectLastPing, selectNetwork, setNetwork } from "./reducers/appSlice";
import { setSelectedTab } from "./reducers/dashboardSlice";
import { fetchUserClientsDocuments } from "./reducers/documentsDataSlice";
import { getDocumentStatus } from "./reducers/documentsStatusSlice";
import {
  fetchDefaultAuthors,
  fetchUserClients,
  fetchUserFields,
  fetchUserMarkets,
} from "./reducers/metadataSlice";
import {
  setCellHeight,
  setCurrentHeight,
  setCurrentWidth,
  setResolutionType,
} from "./reducers/uiSlice";
import { selectUserID } from "./reducers/userSlice";
import { sse } from "./thunks";

export const sideEffects = () => {
  const dispatch = useAppDispatch();
  const initialized = useRef(false);

  const userId = useAppSelector(selectUserID);

  const lastPing = useAppSelector(selectLastPing);
  const isNetwork = useAppSelector(selectNetwork);

  const [width, height] = useWindowSize();

  const handleDimChanges = (width: number, height: number) => {
    const res = getDimensionForResolution(width, height);
    dispatch(setResolutionType(res));
    dispatch(setCurrentHeight(height));
    dispatch(setCurrentWidth(width));
    dispatch(setCellHeight(res.rowHeight));
    //adjustUIParametersForScreenSizeChange(res, width, height);
  };

  // Verify the connection for the user and update the details in the db
  // Make an binding to the backend server for receivng the SSE
  const handleSSE = async () => {
    let connectionId = uuidv4().toString();
    const res = await fetchConnectionId(userId);

    if (res == undefined) {
      upsertUserDetails({
        userExternalId: userId.toString(),
        connectionId: connectionId,
        isPersisted: isNetwork,
      });
    } else {
      connectionId = res.connectionId;
    }

    if (isNetwork) {
      dispatch(sse(connectionId));
    }
  };

  // Online Detector
  useInterval(
    () => {
      if (
        (lastPing && performance.now() - lastPing > 5000) ||
        !navigator.onLine
      ) {
        dispatch(setNetwork(false));
      } else {
        dispatch(setNetwork(true));
      }
    },
    lastPing,
    1000
  );

  if (!initialized.current) {
    initialized.current = true;

    // Initialize the UI Dimension based on screen size
    handleDimChanges(width, height);

    // Handle the SSE
    handleSSE();

    // Handle the Authors
    dispatch(fetchDefaultAuthors());

    // Handle the Markets
    dispatch(fetchUserMarkets(userId.toString()));

    // Handle the document Status
    dispatch(getDocumentStatus());
    // handleDocumentStatus();

    // Handle the Fields
    dispatch(fetchUserFields(userId.toString())).then(async () =>
      // Handle the Clients
      dispatch(fetchUserClients(userId.toString())).then(async () => {
        const noOfFields = (await fetchOfflineFields()).length;
        const clients = await fetchOfflineClients();

        // Handle the Documents
        if (clients && clients.length > 0) {
          dispatch(
            fetchUserClientsDocuments({
              clientIds: clients.map((client) => client.id),
              userId: userId.toString(),
            })
          );
        }

        setupLocalStorageWithDummyRows(clients, noOfFields);

        dispatch(setSelectedTab(clients?.at(0)?.id));
      })
    );

    // Prepare Local Storage
    if (localStorage.getItem(CLIENT_NAME_COUNTER_STORAGE_KEY) == null) {
      localStorage.setItem(CLIENT_NAME_COUNTER_STORAGE_KEY, "1");
    }

    if (localStorage.getItem(DELETE_ACTION_DOC_IDS) == null) {
      localStorage.setItem(DELETE_ACTION_DOC_IDS, JSON.stringify([]));
    }
  }
};
