import { useEffect, useRef, useState } from "react";
import "react-edit-text/dist/index.css";
import { FieldValueMetadata } from "../../../common/types/dashboard/DashboardUITypes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectSelectedDocumentHTML,
  selectSelectedDocumentName,
  selectSelectedDocumentValuesMapped,
} from "../../../redux/reducers/dashboardSlice";
import { selectDocumentID } from "../../../redux/reducers/documentSlice";
import { VType } from "../../../redux/reducers/documentsSlice";
import { setClose } from "../../../redux/reducers/popupSlice";
import {
  revertDocumentFieldValue,
  updateDocumentFieldValue,
} from "../../../redux/thunks";
import "./Edit.scss";
import EditableValue from "./editable-value/EditableValue";
import { PuffLoader } from "react-spinners";
import classNames from "classnames";

function Edit() {
  const dispatch = useAppDispatch();
  const docId = useAppSelector(selectDocumentID);
  const html = useAppSelector(selectSelectedDocumentHTML);

  // File Name
  const fileName = useAppSelector(selectSelectedDocumentName);

  const [prevFieldCodeAccessed, setPrevFieldCodeAccessed] = useState("");

  const [selectedField, setSelectedField] = useState("");

  const values = useAppSelector(selectSelectedDocumentValuesMapped);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  // useEffect(() => {
  //   const handler = (ev: MessageEvent<{ type: string; message: string }>) => {
  //     console.log("ev", ev);

  //     if (typeof ev.data !== "object") return;
  //     if (!ev.data.type) return;
  //     if (ev.data.type !== "button-click") return;
  //     if (!ev.data.message) return;

  //     console.log(ev.data.message);
  //   };

  //   window.addEventListener("message", handler);

  //   // Don't forget to remove addEventListener
  //   return () => window.removeEventListener("message", handler);
  // }, []);

  function handleSave(input: {
    name: string;
    value: string;
    previousValue: string;
  }): void {
    if (input.value !== input.previousValue) {
      dispatch(
        updateDocumentFieldValue({
          docId: docId,
          fieldCode: input.name,
          newValue: input.value,
          oldValue: input.previousValue,
        })
      );
    }
  }

  function handleRevert(value: VType) {
    let field = {
      docId: docId,
      fieldCode: value.id.toString(),
      newValue: value.prevValue,
      oldValue: value.value,
    };
    dispatch(revertDocumentFieldValue(field)).then(() => {
      field.fieldCode = value.key;
      // dispatch(setFieldValue(field));
    });
  }

  const addHighlight = (element: HTMLCollection) => {
    for (let i = 0; i <= element.length - 1; i++) {
      let startingElement = element.item(i);
      // startingElement?.classList.remove("hl-yellow");
      startingElement?.setAttribute(
        "style",
        "background-color: #FAD47F !important"
      );
    }
  };

  const removeHightlight = (element: HTMLCollection) => {
    for (let i = 0; i <= element.length - 1; i++) {
      let startingElement = element.item(i);
      startingElement?.classList.add("hl-yellow");
      startingElement?.setAttribute("style", "");
    }
  };

  const handleNavigation = (fieldCode: string) => {
    if (fieldCode != prevFieldCodeAccessed) {
      setSelectedField(fieldCode);
      let parentElement =
        iframeRef.current?.contentWindow?.document.getElementById(
          "page-container"
        );

      const pageElements =
        iframeRef.current?.contentWindow?.document.getElementsByClassName(
          "pf w0 h0"
        );

      if (pageElements) {
        for (let index = 0; index < pageElements.length; index++) {
          const page = pageElements[index];
          const fieldElement = page.getElementsByClassName(
            "hl-yellow " + fieldCode
          );
          if (fieldElement && fieldElement.length > 0) {
            page?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            fieldElement[0]?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });

            // fieldElement?.scrollTo({
            //   top: fieldElement[0].getBoundingClientRect().y,
            //   left: fieldElement[0].getBoundingClientRect().x,
            //   behavior: "smooth",
            // });

            addHighlight(fieldElement);

            if (prevFieldCodeAccessed != "") {
              const prevFieldElement =
                iframeRef.current?.contentWindow?.document.getElementsByClassName(
                  prevFieldCodeAccessed
                );
              if (
                prevFieldElement != undefined &&
                prevFieldElement?.length > 0
              ) {
                removeHightlight(prevFieldElement);
              }
            }

            setPrevFieldCodeAccessed(fieldCode);
          }
        }
      }
    }
  };

  const getValues = (values: FieldValueMetadata[]) => {
    if (values != undefined) {
      return values
        .filter((value) => Object.keys(value).length > 0)
        .map((value: FieldValueMetadata, index: number) => {
          return (
            <div key={value.field} id={value.field} className="value-pair">
              <label style={{ cursor: "default" }} className="value-label">
                {value.name}
              </label>
              <div onClick={() => handleNavigation(value.field)}>
                <EditableValue
                  field={value}
                  isSelected={selectedField == value.field}
                ></EditableValue>
              </div>
            </div>
          );
        });
    }
  };

  return (
    <div className="abstractor">
      <div className="abstractor-header">
        <span className="abstractor-heading">LOI Abstraction: </span>
        <span className="abstractor-file-name">&nbsp;{fileName}</span>
      </div>
      <div className="abstractor-divider"></div>
      <div className="absractor-divide">
        <div
          className={classNames("html-loader", {
            hide: html != undefined,
          })}
        >
          <PuffLoader color="#FFB000" speedMultiplier={2} />
        </div>

        <iframe
          className={classNames({ hide: html == undefined })}
          ref={iframeRef}
          srcDoc={html}
          height="100%"
          width="100%"
        ></iframe>
        <div className="absractor-values">
          <div className="value-container">{getValues(values)}</div>
          <div className="action-container">
            <button
              onClick={() => dispatch(setClose())}
              className="button-grey action-buttons export-button"
            >
              <span className="button-text">Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
