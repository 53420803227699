import { AuthorOfflineEntity } from "../common/types/DB";
import { db } from "./db";

/**
 * Author Details
 */
export const fetchOfflineAuthors = () => {
  return db.authorValues.toArray();
};

export const upsertAuthors = (values: AuthorOfflineEntity[]) => {
  if (values) return db.authorValues.bulkPut(values);
};
